import React from "react";
import moment from "moment";
import { Line } from 'react-chartjs-2';
import { Resizable } from 'react-resizable';
import 'chartjs-adapter-moment';  // Nuevo: Importa el adaptador moment
import { Contenedor, Main } from './ElementsDisplay';

export default function App({ info }) {
  let options = {
    scales: {
      x: {  // Nuevo: Utiliza 'x' en lugar de 'xAxes'
        type: 'time',
        time: {
          unit: 'hour',
          tooltipFormat: 'll HH:mm'
        }
      }
    },
    maintainAspectRatio: false
  };

  // Ajustar la hora en el eje X restando 5 horas
  const adjustedEjex = info.map((fecha) => {
    const fechaD = fecha.fechaCompleta || fecha.fecha;
    const date = new Date(fechaD);
    date.setHours(date.getHours() + 5);
    return date;
  });

  // Ajustar la hora en el eje X restando 5 horas
  const valorHumedad = info.map((valor) => {
    return valor.temperature || valor.temperatura || valor.Tambiente;
  });

  const data = {
    labels: adjustedEjex,
    datasets: [
      {
        label: "Temperatura",
        data: valorHumedad,
        fill: true,
        pointRadius: 1.8,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)"
      }
    ]
  };

  // Nuevo: Agrega configuración de plugins si es necesario
  options.plugins = {
    tooltip: {
      callbacks: {
        label: (context) => {
          const index = context.dataIndex;
          const humedad = valorHumedad[index];
          return `Temperatura: ${humedad}`;
        }
      }
    }
  };

  return (
    <>
      {info.length > 0 ? <>
        <Main>
          <Contenedor>
            <Resizable>
              <Line data={data} options={options} />
            </Resizable>
          </Contenedor>
        </Main>
      </> : <p>No hay Datos</p>}
    </>
  );
}