import React, { useEffect, useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import Api from "../../services/api-service";
import AUTH from "../../services/auth-service"
import "react-datepicker/dist/react-datepicker.css";
import ReactToExcel from 'react-html-table-to-excel';
import Checkbox from '@mui/material/Checkbox';
import { useLocation } from "react-router-dom";
import {
    ContenedorTabla,
    ContenedorTitulo,
    Main,
    Th,
    Titulo,
    Tr,
    TablaDispo,
    TrC,
    BotonDatos,
} from "./filtrado";

const horas = [
    { value: "00", label: "00H00" },
    { value: "01", label: "01H00" },
    { value: "02", label: "02H00" },
    { value: "03", label: "03H00" },
    { value: "04", label: "04H00" },
    { value: "05", label: "05H00" },
    { value: "06", label: "06H00" },
    { value: "07", label: "07H00" },
    { value: "08", label: "08H00" },
    { value: "09", label: "09H00" },
    { value: "10", label: "10H00" },
    { value: "11", label: "11H00" },
    { value: "12", label: "12H00" },
    { value: "13", label: "13H00" },
    { value: "14", label: "14H00" },
    { value: "15", label: "15H00" },
    { value: "16", label: "16H00" },
    { value: "17", label: "17H00" },
    { value: "18", label: "18H00" },
    { value: "19", label: "19H00" },
    { value: "20", label: "20H00" },
    { value: "21", label: "21H00" },
    { value: "22", label: "22H00" },
    { value: "23", label: "23H00" },
];

const Index = () => {
    const [triggerEspecificos, setTriggerEspe] = useState(true)
    const [triggerTodos, setTriggerTodos] = useState(true)
    const [ingresoDatos, setIngreso] = useState(false)
    const [todoDispositivos, setTodosDispositivos] = useState([]);
    const query = new URLSearchParams(useLocation().search);
    const localizacion = query.get("localizacion");
    const [horass, setHoras] = useState([]);
    const [labelDispo, setLabelDispo] = useState([]);
    const [fecha, setFecha] = useState("");
    const [dispData, setDispData] = useState([]);
    const [dispositivosGet, setDispositivosGet] = useState([]);
    const [dispositivosere, setDispositivos] = useState([]);
    const [tipoDispositivo, setTipoDispositivo] = useState()
    const [contro, setControl] = useState(false);

    let arreglo = [];
    let arreglo2 = [];
    let arreglo3 = [];

    useEffect(async () => {
        const auth = await AUTH.getOrganizaciones()
        let datas = ""

        auth.map((index) => {
            index.localizacion.map((locali) => {
                if (locali.localiza === localizacion) {
                    locali.configuraciones.map((conf) => {
                        datas = conf.catalogo
                    })
                }
            })
        })

        setTipoDispositivo(datas)

        Api.ObtenerDispositivosHumi(datas).then(
            (result) => {

                let datosDispositivo = []
                result.map((index, i) => {
                    let arreglo = {
                        value: index.deviceID,
                        label: index.nombre,
                    }
                    datosDispositivo.push(arreglo)
                })
                setTodosDispositivos(datosDispositivo)
            },
            (error) => {
                console.log(error);
            }
        );
    }, [localizacion]);

    const [selectedDay, setSelectedDay] = useState(new Date());


    async function sendData() {
        let datos = await Api.consultaHoras({ arregg: dispositivosere, horass: horass, fecha: fecha, tipo: tipoDispositivo });

        setControl(true)

        setDispData(datos);


    }
    async function sendDataTodos() {

        let arregg = []
        let arregg1 = []
        todoDispositivos.map((index, i) => {
            arregg[i] = index.value;
            arregg1[i] = index.label;
        });

        setLabelDispo(arregg1)
        let datos = await Api.consultaHoras({ arregg: arregg, horass: horass, fecha: fecha, tipo: tipoDispositivo });
        // let datos = await Api.consultaHoras(arregg, horass, fecha);

        setControl(true)

        setDispData(datos);


    }

    function disTodos() {

    }
    function disEspecificos() {
        setTriggerEspe(!triggerEspecificos)
        //console.log(triggerEspecificos);
    }

    function Activar(e) {
        if (e == "todos") {
            setTriggerEspe(!triggerEspecificos)
            setIngreso(!ingresoDatos)
        }
        if (e == "uno") {
            setTriggerTodos(!triggerTodos)
        }

    }
    function Desactivar(e) {

    }

    const onDropdownChange = (value) => {
        arreglo = [];
        arreglo3 = [];
        value.map((index, i) => {
            arreglo[i] = index.value;
            arreglo3[i] = index.label;
        });
        setDispositivos(arreglo);
        setLabelDispo(arreglo3)
    };
    const onDropdownChange2 = (value) => {
        arreglo2 = [];
        value.map((index, i) => {
            arreglo2[i] = index.value;
        });
        setHoras(arreglo2);
    };
    const onTest = (value) => {
        let anio = value.getFullYear();
        let mes = value.getMonth() + 1;
        let dia = value.getDate();
        if (mes < 10) {
            mes = `0${mes}`;
        }
        if (dia < 10) {
            dia = `0${dia}`;
        }
        let fecha = `${anio}-${mes}-${dia}`;
        setFecha(fecha);
        setSelectedDay(value);
    };

    return (
        <>
            <Main>
                <ContenedorTitulo>
                    <Titulo>Filtrado por horas</Titulo>
                </ContenedorTitulo>{
                    contro ? null : null
                }

                {
                    triggerTodos ? <div>Todos los dispositivos <Checkbox onClick={() => {
                        Activar("todos");
                    }} /> </div> : <><div>Todos los dispositivos <Checkbox disabled /></div> </>
                }
                {
                    triggerEspecificos ? <><div>Dispositivos Especificos<Checkbox onClick={() => {
                        Activar("uno");
                    }} /> </div>

                    </> : <><div>Dispositivos Especificos <Checkbox disabled /></div> <Select
                        isMulti
                        name="colors"
                        options={horas}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={onDropdownChange2}
                    />
                        <DatePicker selected={selectedDay} onChange={onTest} /><BotonDatos
                            onClick={() => {
                                sendDataTodos();
                            }}
                        >
                            Consultar
                        </BotonDatos></>
                }


                {triggerTodos ? null : <> <Select
                    isMulti
                    name="colors"
                    options={todoDispositivos}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={onDropdownChange}
                />
                    <Select
                        isMulti
                        name="colors"
                        options={horas}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={onDropdownChange2}
                    />
                    <DatePicker selected={selectedDay} onChange={onTest} /><BotonDatos
                        onClick={() => {
                            sendData();
                        }}
                    >
                        Consultar
                    </BotonDatos></>

                }





                <ContenedorTabla>




                    {dispData <= 0 ? null :
                        <TablaDispo id="tabla-obtenidas">
                            <Tr>
                                <Th>Dispositivos</Th>
                                {horass.map((index, i) => (
                                    <Th>Hora: {index}H00</Th>
                                ))}
                            </Tr>


                            {tipoDispositivo == "ble_1" ? labelDispo.map((index, j) => (
                                <TrC>
                                    <td>{index}</td>
                                    {dispData[j].map((index, i) => (
                                        index.humidity ?
                                            <td>H: {index.humidity} T: {index.temperature}</td>
                                            : <td>No hay Datos</td>
                                    ))}
                                </TrC>
                            )) : labelDispo.map((index, j) => (
                                <TrC>
                                    <td>{index}</td>
                                    {dispData[j].map((index, i) => (
                                        index.Hambiente ?
                                            <td>H: {index.Hambiente} T: {index.Tambiente}</td>
                                            : <td>No hay Datos</td>
                                    ))}
                                </TrC>
                            ))}






                        </TablaDispo>

                    }
                </ContenedorTabla>

            </Main>
            {contro ? <><ReactToExcel
                className="btn btn-danger button"
                table="tabla-obtenidas"
                filename="iotTablaConste"
                sheet="sheet 1"
                buttonText="Exportar como xls"
            /></> : null}

        </>
    );
};

export default Index;
