import React, { useEffect, useState } from "react";
import Api from "./../../services/api-service";
import SearchIcon from "@material-ui/icons/Search";
import InputBusqueda from "./../../components/Table/inputBusqueda";
import Button from "../../components/Button/index";
import Auth from "../../services/auth-service.js";
import { useLocation, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import {
  BarraBusqueda,
  BotonCrear,
  ContenedorTabla,
  ContenedorTitulo,
  Main,
  NavbarTabla,
  Th,
  Titulo,
  Tr,
  TablaDispo,
  TrC,
  IconoLupa,
  ContenedorDis,
  Nombredispositivo,
  DescripcionDispo,
  Organizacion,
  Tipo,
  ContenedorFecha,
  Tiempo,
  Fecha,
  BotonMostrar,
  BotonDatos,
} from "./AlertasElements";

const Index = () => {
  const [results, setResults] = useState([]);
  const [text, setText] = useState("");
  const [postRol, setRol] = useState("");
  const query = new URLSearchParams(useLocation().search);
  const obteOrganizacion = query.get("organizacion");
  const obteLocalizacion = query.get("localizacion");

  const history = useHistory();


  const cargarAlertas = () => {
    
    Api.getAlertas(text, obteOrganizacion, obteLocalizacion)
      .then((result) => {
        setResults(result);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    setRol(Auth.getRol())
    cargarAlertas();

    const unlisten = history.listen(() => {
      cargarAlertas();
    });

    return () => {
      unlisten();
    };
  }, [text, obteOrganizacion, obteLocalizacion, history]);

  const eliminarAlerta = (id) => {
    Swal.fire({
      title: "¿Seguro que quieres eliminar esta Alerta?",
      showDenyButton: true,
      confirmButtonText: "Confirmar",
      denyButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        Api.eliminarAlerta(obteOrganizacion, obteLocalizacion, id)
          .then((respuesta) => {
            Swal.fire("Alerta eliminada", "", "success").then(() => {
              cargarAlertas(); // Cargar los datos actualizados después de eliminar
            });
          })
          .catch((error) => {
          });
      } else if (result.isDenied) {
        Swal.fire("Alerta no eliminada", "", "info");
      }
    });
  };

  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>Alertas</Titulo>
        </ContenedorTitulo>
      </Main>
      <Main>
        <BarraBusqueda>
          <IconoLupa>
            <SearchIcon />
          </IconoLupa>
          <InputBusqueda setText={setText} text={text} />
        </BarraBusqueda>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {postRol === "administrador" ? (<Button
                url={`/admin/createAlert?organizacion=${obteOrganizacion}&localizacion=${obteLocalizacion}`}
                message="Crear Nuevo"
                style={BotonCrear}
              />) : null}
          <ContenedorTabla>
            <TablaDispo>
              <Tr>
                <Th>Nombre</Th>
                <Th>Estado</Th>
                <Th>Acciónes</Th>
              </Tr>
              {results.length > 0 ? (
                results.map((device, i) => (
                  <TrC key={i}>
                    <td>
                      <Nombredispositivo>{device.nombre}</Nombredispositivo>
                    </td>

                    <td>
                      <Organizacion>{device.organizacion}</Organizacion>
                    </td>
                    <td>
                      <Button
                        url={`/admin/viewAlert?id=${device._id}&localizacion=${obteLocalizacion}&organizacion=${obteOrganizacion}&view=one`}
                        message="Mostrar Datos"
                        style={BotonDatos}
                      />
                      <div>
                      {postRol === "administrador" ? <BotonDatos onClick={() => eliminarAlerta(device._id)}>
                          Eliminar
                        </BotonDatos> : null}
                      </div>
                    </td>
                  </TrC>
                ))
              ) : (
                <div>No hay resultados</div>
              )}
            </TablaDispo>
          </ContenedorTabla>
        </div>
      </Main>
    </>
  );
};

export default Index;
