import React from "react";
import './App.css';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import LoginLayout from "./layouts/Login.js"
import AdminLayout from "./layouts/Admin.js";
import "bootstrap/dist/css/bootstrap.min.css";
import Auth from './services/auth-service.js'


class App extends React.Component {
  auth = Auth.getJWT();
  constructor(props) {
    super(props);
    this.state = { auth: false, counter: 0 };
  }

  handleCallbackLogin = (childData) => {
    this.setState({ auth: childData })
    this.auth = childData;
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route path="/login" render={(props) => <LoginLayout {...props} onLoginCallback={this.handleCallbackLogin} />} />
          <Route path="/admin" render={(props) => (Auth.getJWT() !== null ? <AdminLayout {...props} /> : <Redirect from="/" to="/login/ingreso" />)} />
          <Redirect from="/" to="/admin/home" />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default App;
