import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import API from "../../services/api-service";
import { Form } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
import Tabla from "./Tabla/index";
import { Main, ContenedorTitulo, ContenedorFechas } from "./ElementosStyles";
import { Estado } from "../Dashboard/DashboardElements";

const Index = () => {
    const itemsPerPageOptions = [50, 100, 200];
    const query = new URLSearchParams(useLocation().search);
    const organizacionn = query.get("organizacion");
    const localizacionn = query.get("localizacion");
    const [dispositivoLoca1, setDispositivoLoca1] = useState("all");
    const [dispositivosLoca1, setDispositivosLoca1] = useState([]);
    const [estadoLoca1, setEstadoLoca1] = useState("all");
    const [columnValues, setColumValues] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchDispositivos = async () => {
            let dispositivosResponse = [];
            let columnas = [];

            if (organizacionn === "gpf") {
                if (localizacionn === "gpf_loca_1") {
                    dispositivosResponse = await API.obtenerDispositivosHumitemp({ organizacion: organizacionn, estado: "all" });
                    columnas = [
                        { nColumna: 'Device ID', nValor: 'deviceID' },
                        { nColumna: 'Nombre', nValor: 'nombre' },
                        { nColumna: 'Organizacion', nValor: 'organizacion' },
                        { nColumna: 'Tipo alerta', nValor: 'tipoAlerta' },
                        { nColumna: 'Fecha Alerta', nValor: 'fecha' },
                        { nColumna: 'Revisado', nValor: 'revisado' }
                    ];
                    setDispositivosLoca1(dispositivosResponse);
                }
            }
            setColumValues(columnas);
        };

        const fetchData = async () => {
            const toastId = toast.loading("Cargando datos...");
            setLoading(true);
            try {
                setData([]);
                let respuesta = {};

                if (organizacionn === "gpf" && localizacionn === "gpf_loca_1") {
                    respuesta = await API.adquirirIncidentDevicesBeta({ organizacion: organizacionn, deviceID: dispositivoLoca1, estado: estadoLoca1 });
                }
                if (respuesta.mensaje === "No existen documentos") {
                    toast.error("No existen datos");
                    setData([]);
                } else {
                    setData(respuesta.result || respuesta);
                }
            } catch (error) {
                console.error(error);
                toast.error("Error al cargar los datos históricos");
            } finally {
                toast.dismiss(toastId);
                setLoading(false);
            }
        };

        fetchDispositivos();
        fetchData();
    }, [organizacionn, localizacionn, estadoLoca1, dispositivoLoca1]);

    return (
        <Main>
            <Toaster />
            <ContenedorTitulo>Incidencias dispositivos</ContenedorTitulo>
            <ContenedorFechas>
                <Form.Group controlId="selectDeviceLoca1">
                    <Form.Label>Dispositivos:</Form.Label>
                    <Form.Select value={dispositivoLoca1} onChange={e => setDispositivoLoca1(e.target.value)}>
                        <option value="all">Todos</option>
                        {dispositivosLoca1.map((device, index) => (
                            <option key={index} value={device.deviceID}>
                                {device.nombre}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </ContenedorFechas>
            <ContenedorFechas>
                <Form.Group controlId="selectEstadoLoca1">
                    <Form.Label>Revisados:</Form.Label>
                    <Form.Select value={estadoLoca1} onChange={e => setEstadoLoca1(e.target.value)}>
                        <option value="all">TODO</option>
                        <option value="true">Revisados</option>
                        <option value="false">No revisados</option>
                    </Form.Select>
                </Form.Group>
            </ContenedorFechas>
            {loading ? (
                <div>Cargando...</div>
            ) : data.length > 0 ? (
                <Tabla data={data} itemsPerPageOptions={itemsPerPageOptions} columnValues={columnValues} />
            ) : (
                <div>No existen datos</div>
            )}
        </Main>
    );
};

export default Index;
