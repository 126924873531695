import styled from "styled-components";

export const Main = styled.div`
  margin: 25px 30px 25px 30px;
  height: 100%;
  ${"" /* background: red; */}
`;

export const ContenedorTitulo = styled.h4`
  text-align: center;
  ${'' /* padding: 10px 15px; */}
  padding-bottom: 15px;
  height: 45px;
`;
export const Opciones = styled.div`
padding: 13px 0px 0px 0px;
  ${'' /* margin: 10px; */}
`;

export const BotonGuardar = styled.button`
width: 100px;
height: 40px;
background: #1976d2;
color: white;
border: 1px solid #ccc;
border-radius: 5px;
position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
`;

export const ContenedorBotones = styled.div`
  padding: 15px 0;
  ${'' /* justify-content: flex-end;
  color: white; */}
`;
export const ContenedorBotoness = styled.div`
  align-self: right;
`;