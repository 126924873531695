import styled from "styled-components";

export const Main = styled.div`
  margin: 35px 30px 25px 30px;
  height: 100%;
  ${"" /* background: red; */}
`;
export const ContenedorTitulo = styled.h2`
  text-align: center;
  padding: 10px 15px;
  height: 45px;
`;
export const Titulo = styled.div``;
export const ContenedorCategorias = styled.div`
  padding: 10px 15px

`;
export const Categorias = styled.div`
  display: inline-block;
  margin-left: 25px;
`;
export const ContenedorTabla = styled.div``;
export const NavbarTabla = styled.div`
  ${"" /* width: 100%; */}
  margin: 30px 15px;
  ${"" /* background: #254d8f; */}
  ${"" /* text-align: center; */}
`;
export const BotonFiltro = styled.div`
  display: inline-block;
  ${"" /* display: inline-block;
height: 40px;
width: 95.80007934570312px;
left: 20.373291015625px;
top: 117px;
border-radius: 6px;
background-color: transparent;
border:none; */
  }
`;
export const BarraBusqueda = styled.div`
  display: inline-block;
  margin-left: 42px;
  margin-right: 320px;
  height: 50px;
  width: 392px;
  background: #efefef;
  ${"" /* top: 50%; */}
  padding: 1% 0;

  ${"" /* left: 0px;
top: 0px;
border-radius: 6px;
left: 0%;
right: 0%;
top: 0%;
bottom: 0%;
box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
border-radius: 6px; */
  }

  align-content: center;
  justify-content: flex-start;
  align-items: center;
`;

export const IconoLupa = styled.div`
  display: inline-block;
  margin: 0 10px;
`;
export const InputBusqueda = styled.input`
  display: inline-block;
  width: 320px;
  border: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
  ${"" /* border-radius: 1px; */}
`;
export const BotonMostrar = styled.button`
  display: inline-block;
  float: left;
  margin: 10px;

  background: #114396;
  color: white;
  border-radius: 5px;
  height: 49.999996185302734px;
  width: 210px;
  ${"" /* align-content: center; */}
  text-align: center;
  border: none;
  ${"" /* align-items: center; */}

  ${"" /* display: inline-block;
background: #114396;
color: white;
border-radius: 5px;
height: 49.999996185302734px;
width: 210px;
border:none; */
  }




${"" /* left: -0.1253662109375px;
box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
border-radius: 5px;
left: -0.06%;
right: 0.06%;
bottom: -0.39%;
box-shadow: 6px 0px 18px 0px #0000000F; */
  }
`;

export const BotonVerDash = styled.button`
  margin: 10px;
  background: #114396;
  color: white;
  border-radius: 5px;
  height: 30px;
  border: none;
  width: 210px;
  text-align: center;
`;

export const BotonCrear = styled.button`
  display: inline-block;
  float: right;
  margin: 10px;
  background: #114396;
  color: white;
  border-radius: 5px;
  height: 49.999996185302734px;
  border: none;
  width: 210px;
  ${"" /* align-content: center; */}
  text-align: center;
  ${"" /* align-items: center; */}

  ${"" /* display: inline-block;
background: #114396;
color: white;
border-radius: 5px;
height: 49.999996185302734px;
width: 210px;
border:none; */
  }

${"" /* left: -0.1253662109375px;
box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
border-radius: 5px;
left: -0.06%;
right: 0.06%;
bottom: -0.39%;
box-shadow: 6px 0px 18px 0px #0000000F; */
  }
`;

export const Tr = styled.tr`
  ${"" /* position: absolute; */}
  left: 0.03%;
  right: 0.06%;
  top: 19.77%;
  bottom: 75.08%;
  background: #efefef;
  height: 45px;
`;

export const Th = styled.th`
  left: 25.01%;
  right: 61.36%;
  top: 21.49%;
  bottom: 76.46%;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #4f4f4f;
  text-align: center;
  padding: 15px;
`;

export const TablaDispo = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const TrC = styled.tr`
  text-align: center;
  ${"" /* margin: 15px 0 15px 0; */}
  ${"" /* border: solid; */}
border-width: 1px 0;
  border-color: #dadada;
  ${"" /* height: 30px; */}
  ${"" /* background: red; */}
`;

export const CoFiltroIn = styled.div`
  height: 45px;
  width: 100%;
  background: #efefef;
  text-align: right;
`;

export const Divfiltro = styled.div`
  display: inline-block;
  /* Filas por página : 10 */
  margin: 10px 30px 0 30px;

  /* Table/Footer */

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  letter-spacing: 0.05em;

  /* text */
`;

export const Stiemererpo = styled.td``;

export const SelectFiltro = styled.select`
  border: none;
  background: #efefef;
`;
// dsdsd

export const ContenedorDis = styled.div``;
export const Nombredispositivo = styled.div`
  color: #114396;
`;
export const DescripcionDispo = styled.div`
  color: #4f4f4f;

  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  ${"" /* text-align: left; */}
`;
export const Organizacion = styled.div`
  color: #114396;
`;
export const Tipo = styled.div`
  color: #4f4f4f;
`;
export const ContenedorFecha = styled.div``;
export const Estado = styled.div`
  color: #114396;
`;
export const Fecha = styled.div`
  color: #4f4f4f;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  ${"" /* text-align: left; */}
`;
export const Tiempo = styled.div`
  color: #114396;
`;
export const Puntos = styled.div`
  color: #4f4f4f;
`;

// nombre
// organizacion
// cantidadUsuarios
// estado
export const BotonDatos = styled.button`
  display: inline-block;
  ${"" /* float: right; */}
  margin: 10px;
  background: #0d6efd2e;
  color: black;
  border-radius: 5px;
  height: 42px;
  width: 110px;
  text-align: center;
  border: none;
`;

export const BotonAzul = styled.button`
  display: inline-block;
  ${"" /* float: right; */}
  margin: 10px;
  background: #114396;
  color: white;
  border-radius: 5px;
  height: 42px;
  width: 110px;
  text-align: center;
  border: none;
`;

export const ParaBoton = styled.div`
  padding: 15px 15px;
  ${"" /* background: red; */}
`;
export const ParaMapa = styled.div`
  text-align: center;
  ${"" /* background: blue; */}
  height: 50px;
  ${"" /* padding: 50px 15px; */}
`;

export const BotonDevice = styled.button`
  display: inline-block;
  margin: 10px;
  background: #114396;
  color: white;
  border-radius: 5px;
  height: 49.999996185302734px;
  border: none;
  width: 210px;

  text-align: center;
`;

export const ContenedorButton = styled.div`
  padding: 25px 0;
  text-align: center;
`;

export const ContenedorWidget = styled.div`
  margin: 50px 0px;
  display: inline-block;
  background: red;
`;

export const Widget = styled.div`
  text-align: center;
  display: inline-block;
  height: 300px;
  width: 350px;
  ${"" /* background: red; */}
`;

export const ParaImagen = styled.img`
  width: 80px;
  height: 80px;
  margin: 10px;
`;

export const Example = styled.div`
${'' /* background: red;  */}
  width: 100%;
  height: 100%;
`;

export const CTituloModal = styled.div`
padding: 15px 15px 15px 15px;
background: #1976d2;
`;

export const CtituloWi = styled.div`
  margin-top: 15px;
  padding: 8px;
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CtituloLe = styled.div`
  color: rgb(94, 94, 94);
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: bold;
  margin: 0px;
  overflow: hidden;
  padding: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`;

export const IconoDes = styled.div`
  color: rgb(94, 94, 94);
  font-family: Nunito, sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: bold;
  margin: 0px;
  overflow: hidden;
  padding: 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TituloModal = styled.h2`
  text-align: center;
  color: white;
`;

export const ContenedorBotones = styled.div`
  padding: 15px 0;
  ${'' /* justify-content: flex-end;
  color: white; */}
`;
export const ContenedorBotoness = styled.div`
  align-self: right;
`;
export const BotonM = styled.button`
padding: 7px 18px;
 position: absolute;
   bottom: 20px;
   margin: 0 15px 0 0;
`;
export const BotonMs = styled.button`
padding: 7px 18px;
 position: absolute;
   bottom: 20px;
   margin: 0 15px 0 0;
`;

export const ContenedorTablas = styled.div`
max-height: 80%;
overflow-y: scroll;
`;
export const MenuHamb = styled.div`
  ${'' /* width: 350px; */}
  display: flex;
  justify-content: center;
  display: inline-block;

`;

export const IcoHam = styled.div`
display: inline-block;
margin-right: 10px;
`;
export const TituloHam = styled.div`
  display: inline-block;
  font-size: 28px;
  flex-grow: 1;
  text-align: left;
    ${'' /* background: red; */}

  ${'' /* margin: 0 130px 0 0; */}
`;



export const TituloDash = styled.div`
font-weight: bold;
  font-size: 1.2em;
  font-family: "Arial", sans-serif;
`;

export const ContenedorAdd = styled.div`
  display: flex;
  justify-content: flex-end;
  ${'' /* Para centra todo dentro del div */}
  align-items: center;
`;

export const TituloAdd = styled.div`
  /* Estilos para el título */
`;

export const WidgetAdd = styled.div`
  /* Estilos para el widget */
`;


export const ContenedorWi = styled.div`
 display: inline-block;
 justify-content: center;
 text-align: center;
 padding: 15px 15px;
  /* Estilos para el widget */
`;

export const Widg = styled.i`
 display: inline-block;
 margin-bottom: 8px; 
`;

export const ImagenWid = styled.img`
 display: inline-block;
 width: 60px;
 height: 60px;
`;

export const LeyendaD = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: rgb(94, 94, 94)
    
`;

export const ContenedorSe = styled.div`
  margin-top:20px;
`;

export const Back = styled.div`
align-items: center;
padding-bottom: 15px;
`;
export const BackD = styled.div`
display: inline-block;
font-size: 12px;
`;

export const Contenido = styled.div`
margin: 14px 14px;
`;

export const SelecDive = styled.div`
overflow-y: auto;
max-height: 10%;
`;

export const CTituloDis = styled.div`
  height: 40px;
  border-top: 1px solid rgb(242 242 242);
`;
export const TituloDis = styled.div`
margin-left: 12px;
display: inline-block;
`;

export const BotonGuardar = styled.button`
width: 100px;
height: 40px;
background: #1976d2;
color: white;
border: 1px solid #ccc;
border-radius: 5px;
position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 20px;
  margin-right: 20px;
`;

export const BotonEliminar = styled.button`
width: 100px;
height: 40px;
background: red;
color: white;
border: 1px solid #ccc;
border-radius: 5px;
position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 20px;
  margin-left: 20px;
`;

export const Opciones = styled.div`
  margin: 10px;
`;
