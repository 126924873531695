import React, { useEffect, useState } from "react";
import Switch from '@material-ui/core/Switch';
import Auth from "../../../services/auth-service.js";
import Swal from "sweetalert2";
import {
    ContenedorTitulo,
    Main,
    Titulo,
    ContenedorAciones,
    Aciones,
    ContenedorMensajes,
    TituloMensaje,
    Mensajes,
    ContenedorRaccion,
    BotonAccion,
    TituloAccion,
    ContenedorM,
    AcionesD,
    ContendorRepeticion,
    EntradaHoraP,
    SelectHoraP,
    BotonSiguiente2,
    Verificacion
} from "../AlertasElements";
import Select from "../../../components/Entradas/Select/indexText";
import Input2 from "../../../components/Entradas/Input2/index";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

let Tipo = [
    {
        value: "@/deviceID/@",
        label: "Device ID",
    },
    {
        value: "@/value/@",
        label: "Valor",
    },
    {
        value: "@/nameValue/@",
        label: "Nombre Dispositivo",
    },
    {
        value: "@/datetrgigger/@",
        label: "Fecha que se Activo",
    },
    {
        value: "@/nameAlert/@",
        label: "Nombre Alerta",
    },
    {
        value: "@/valueTrigger/@",
        label: "Valor que activo",
    }
];

let Tipo2 = [
    {
        value: "@/deviceID/@",
        label: "Device ID",
    },
    {
        value: "@/value/@",
        label: "Valor",
    },
    {
        value: "@/nameValue/@",
        label: "Nombre Dispositivo",
    },
    {
        value: "@/datetrgigger/@",
        label: "Fecha que se Activo",
    },
    {
        value: "@/nameAlert/@",
        label: "Nombre Alerta",
    },
    {
        value: "@/valueTrigger/@",
        label: "Valor que activo",
    },
    {
        value: "@/tiempoDemora/@",
        label: "Tiempo Demora",
    },
];
const Mensaje = () => {
    const [documento, setDocumento] = useState({});
    const [vMensajeT, setVMensajeT] = useState(false);
    const [vMensajeD, setVMensajeD] = useState(false);
    const [test3, setTest3] = useState(false);
    const [test4, setTest4] = useState(false);

    const [mensaje, setMensaje] = useState("");
    const [mensajeThen, setMensajeThen] = useState("");
    const [mensajeTrigger, setMensajeTrigger] = useState("");
    const [formatoTiempo, setFormatoTiempo] = useState("minuto");
    const [totalMinutos, setTotalMinutos] = useState(0);
    const [totalMensajes, setTotalMensajes] = useState(0);
    const [test, setTest] = useState(false);
    const [state, setState] = useState(false)
    const query = new URLSearchParams(useLocation().search);
    const obteOrganizacion = query.get("organizacion");
    const obteLocalizacion = query.get("localizacion");

    let history = useHistory();

    function testing() {
        setTest(!test)
    }
    function returnPage() {

        history.push(`/admin/createCondition?organizacion=${obteOrganizacion}&localizacion=${obteLocalizacion}`);
    }
    const handleChange = (event) => {
        setState(event.target.checked);
    };
    const formatoMHD = (event) => {
        setFormatoTiempo(event.target.value);
    };

    function sendData() {
        let calculo;

        if (mensaje == "") {
            setVMensajeT(true)
        } else if (mensajeThen == "") {
            setVMensajeT(false)
            setVMensajeD(true)
        } else {
            setVMensajeD(false)
            setVMensajeT(false)

            if (state) {
                let calculo
                documento.stateRepeat = true
                if (formatoTiempo == "dia") {
                    calculo = ((totalMinutos * 1440) / 1)
                } else if (formatoTiempo == "hora") {
                    calculo = ((totalMinutos * 60) / 1)
                } else if (formatoTiempo == "minuto") {
                    calculo = totalMinutos
                }
                documento.totalMinutes = calculo
                documento.totalMessage = totalMensajes
                documento.dispositivos.map((index) => {
                    index.auxMessage = totalMensajes
                })
            } else {
                documento.stateRepeat = false
            }

            documento.mensajeTriiger = mensaje;
            documento.messageThen = mensajeThen;
            Auth.saveAlert(documento)
            Swal.fire("Guardado exitosamente", "", "success");

            history.push(`/admin/mediaAlert?organizacion=${obteOrganizacion}&localizacion=${obteLocalizacion}`);
        }

        // if (mensaje == "" || mensajeThen == "") {
        //     Swal.fire({
        //         title: 'No a ingreado ningun mensaje, quiere continuar sin guardar un mensaje?',
        //         showDenyButton: true,
        //         showCancelButton: true,
        //         confirmButtonText: 'Continuar',
        //         denyButtonText: `Escribir Mensaje`,
        //     }).then((result) => {
        //         /* Read more about isConfirmed, isDenied below */
        //         if (result.isConfirmed) {
        //             Swal.fire('Saved!', '', 'success')
        //         } else if (result.isDenied) {
        //             Swal.fire('Changes are not saved', '', 'info')
        //         }
        //     })
        // }


        // if (state) {
        //     if (formatoTiempo == "dia") {
        //         calculo = ((totalMinutos * 1440) / 1)
        //     } else if (formatoTiempo == "hora") {
        //         calculo = ((totalMinutos * 60) / 1)
        //     } else if (formatoTiempo == "minuto") {
        //         calculo = totalMinutos
        //     }
        // }

        // history.push("/admin/mediaAlert");

    }

    useEffect(() => {
        let prueba

        prueba = Auth.getAlert()
        setDocumento(prueba)
    }, [mensaje, mensajeThen, state]);


    return (
        <>
            <Main>
                <ContenedorTitulo>
                    <Titulo> Mensajes de Activación </Titulo>
                </ContenedorTitulo>
                {test ? <>
                    <ContenedorAciones>
                        <AcionesD onClick={testing}>Cuando se active</AcionesD>
                        <Aciones >Cuando regresa a la
                            normalidad</Aciones>
                    </ContenedorAciones>
                    <ContenedorM>
                        <ContenedorMensajes>
                            <TituloMensaje>Mensaje para cuando vuelva a los valores normales</TituloMensaje>
                            <Select
                                message="Variable"
                                value=""
                                setFunction={setMensajeThen}
                                text={mensajeThen}
                                data={Tipo2}
                                id="simple-disabled"
                            ></Select>
                            <Mensajes onChange={(e) => setMensajeThen(e.target.value)} value={mensajeThen} />
                            {vMensajeD ? <Verificacion>
                                *Debe ingresar un mensaje cuando regrese a la normalidad
                            </Verificacion> : null}

                            {vMensajeT ? <Verificacion>
                                *Debe ingresar un mensaje cuando se active la alerta
                            </Verificacion> : null}

                            <ContenedorRaccion>
                                <BotonAccion>
                                    <Switch
                                        checked={state}
                                        onChange={handleChange}
                                        color="primary"
                                        name="checkedB"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </BotonAccion>
                                <TituloAccion>Repetir esta accion</TituloAccion>
                            </ContenedorRaccion>
                            {state ? <ContendorRepeticion>
                                Repetir la acción cada
                                <EntradaHoraP value={totalMinutos} onChange={(e) => setTotalMinutos(e.target.value)} /><SelectHoraP onChange={formatoMHD}>
                                    <option value="minuto" selected>Minuto(s)</option>
                                    <option value="hora">Hora(s)</option>
                                    <option value="dia">Dias(s)</option>
                                </SelectHoraP> hasta <EntradaHoraP value={totalMensajes} onChange={(e) => setTotalMensajes(e.target.value)} />veces mientras la alerta este activada.
                            </ContendorRepeticion> : null}

                        </ContenedorMensajes>
                    </ContenedorM>
                </> : <><ContenedorAciones><Aciones>Cuando se active</Aciones>
                    <AcionesD onClick={testing}>Cuando regresa a la
                        normalidad</AcionesD>
                </ContenedorAciones>
                    <ContenedorM>
                        <ContenedorMensajes>
                            <TituloMensaje>Mensaje para cuando se active los condicionales</TituloMensaje>
                            <Select
                                message="Variable"
                                value=""
                                setFunction={setMensaje}
                                text={mensaje}
                                data={Tipo}
                                id="simple-disabled"
                            ></Select>
                            <Mensajes onChange={(e) => setMensaje(e.target.value)} value={mensaje} />
                            {vMensajeD ? <Verificacion>
                                *Debe ingresar un mensaje cuando regrese a la normalidad
                            </Verificacion> : null}

                            {vMensajeT ? <Verificacion>
                                *Debe ingresar un mensaje cuando se active la alerta
                            </Verificacion> : null}
                        </ContenedorMensajes>
                    </ContenedorM></>}
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                    <BotonSiguiente2 onClick={sendData}>Siguiente</BotonSiguiente2> 
                    <BotonSiguiente2 onClick={returnPage}>Atras</BotonSiguiente2>
                </div>

            </Main>
        </>
    )
}

export default Mensaje