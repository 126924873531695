import React, { useState, useEffect } from "react";
import { Main, ContenedorTitulo, ContenedorFechas, CTituloModal, TituloModal, OpcionesModal, BotonCrear } from "../ElementosStyles";
import API from "../../../services/api-service"
import toast, { Toaster } from "react-hot-toast";
import Tabla from "../Tabla/index";
import { Button } from 'react-bootstrap';
import ModalR from "../../../components/Modal/Modal right-left/index";
import TextField from "@mui/material/TextField";
import Informa from "../../../components/Informacion/index";
import Swal from "sweetalert2";
import Buton from "../../../components/Button/index"


const Index = () => {
  const [data, setData] = useState([])
  const itemsPerPageOptions = [10, 20, 30]
  const [showEditPro, setShowEditPro] = useState(false)
  const [proudctoSeleccionado, setProductoSeleccionado] = useState()
  const [nombreNuevo, setNombreNuevo] = useState()
  const [nuevaDescripcion, setNuevaDescripcion] = useState()

  const columnas = [
    { nColumna: 'Código', nValor: 'codigo' },
    { nColumna: 'Nombre', nValor: 'nombre' },
    { nColumna: "Descripción", nValor: 'Descripcion' },
    { nColumna: "", nValor: 'accionesProductos' },
  ]

  const cargarData = () => {
    toast.promise(
      API.obtenerProductosFav().then((result) => {
        setData(result)
      }),
      {
        loading: "Cargando...",
        success: <b>Datos cargados!</b>,
        error: (err) => <b>Error al cargar datos!</b>,
      }
    )

  }

  useEffect(() => {
    cargarData();
  }, []);

  const showModalEditarProducto = (producto) => {
    setProductoSeleccionado(producto);
    setNombreNuevo(producto.nombre);
    setNuevaDescripcion(producto.Descripcion)
    setShowEditPro(true)
  }

  const closeModalEditarProducto = () => {
    setShowEditPro(false)
  }

  const actualizarProducto = () => {
    if (proudctoSeleccionado.nombre != nombreNuevo) {
      proudctoSeleccionado.nombre = nombreNuevo
    }
    if (proudctoSeleccionado.Descripcion != nuevaDescripcion) {
      proudctoSeleccionado.Descripcion = nuevaDescripcion
    }

    let documento

    if (nuevaDescripcion != "") {
      documento = {
        _id: proudctoSeleccionado._id,
        nombre: nombreNuevo,
        descripcion: nuevaDescripcion,
      }
    } else {
      documento = {
        _id: proudctoSeleccionado._id,
        nombre: nombreNuevo
      }
    }

    API.actualizacionProducto(documento).then((respuesta) => {
      if (respuesta.success == true) {
        // Swal.fire({ title: "Rango creado/actualizado", icon: "success", confirmButtonColor: "#1976d2", })
        Swal.fire("Guardado exitosamente", "", "success");
        closeModalEditarProducto()
      } else {

      }
    })

    
  }

  const verificarNuevoNombre = (e) => {
    const inputValue = e.target.value;
    setNombreNuevo(inputValue)
  }

  const verificarNuevaDescrip = (e) => {
    const inputValue = e.target.value;
    setNuevaDescripcion(inputValue)
  }

  const deleteProducto = (producto) => (event) => {
    event.preventDefault();
    Swal.fire({
        title: "Confirmar operación",
        text: `Seguro que desea eliminar el producto ${producto.nombre}`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#1976d2",
        cancelButtonColor: "#d33",

    }).then((event) => {
        if (event.isConfirmed) {
            API.eliminarProductoFavorita(producto._id)
                .then((respuesta) => {
                    Swal.fire({ title: "Producto eliminado", icon: "success", confirmButtonColor: "#1976d2", }).then(() => {
                      cargarData()
                    });
                })
            
        }
    });
};

  return (
    <>
      {proudctoSeleccionado != null && (
        <ModalR isOpen={showEditPro} onClose={closeModalEditarProducto} >
          <CTituloModal>
            <TituloModal>Editar Producto</TituloModal>
          </CTituloModal>
          <ContenedorTitulo>Parámetros</ContenedorTitulo>
          <OpcionesModal>
            <TextField
              label="Nombre Producto"
              value={nombreNuevo}
              onChange={verificarNuevoNombre}
            />
          </OpcionesModal>
          <OpcionesModal>

            <TextField
              label="Descripción Producto"
              value={nuevaDescripcion}
              onChange={verificarNuevaDescrip}
              style={{ width: '50%' }}
            />
            <Informa
              info="La descripción es opcional"
            />
          </OpcionesModal>

          <Button onClick={actualizarProducto}>ACTUALIZAR</Button>
        </ModalR>
      )}

      <Main>
        <ContenedorTitulo>PRODUCTOS</ContenedorTitulo>
        <ContenedorFechas>
          <Buton
            url={`/admin/crearProducto`}
            message="Crear Producto"
            style={BotonCrear}
          />
            
        </ContenedorFechas>

        {data.length > 0 ? <Tabla data={data} itemsPerPageOptions={itemsPerPageOptions} columnValues={columnas} setShowEditPro={showModalEditarProducto} deleteProducto={deleteProducto} /> : null}

      </Main>
    </>
  )


}

export default Index