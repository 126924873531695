import styled from "styled-components";

export const Tarjeta = styled.div`
  display: block;
  text-align: center;
  box-shadow: inset 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  margin: 15px 0;
  padding: 15px 0;
`;

export const Main = styled.div`
  margin: 25px 30px 25px 30px;
  height: 100%;
  ${"" /* background: red; */}
`;
export const ContenedorTitulo = styled.h2`
  text-align: center;
  padding: 10px 15px;
  height: 45px;
`;
export const Titulo = styled.div``;
export const ContenedorFechas = styled.div`
    margin: 0px 20px 10px 0px;
    display: inline-block;
`;

export const ContenedorF = styled.div`

`;

export const Categorias = styled.div`
  display: inline-block;
  margin-left: 25px;
`;
export const ContenedorTabla = styled.div``;
export const NavbarTabla = styled.div`
  ${"" /* width: 100%; */}
  margin: 30px 15px;
  ${"" /* background: #254d8f; */}
  ${"" /* text-align: center; */}
`;

export const TituloDispositivo = styled.div`
  text-align: center;
  font-family: "Arial", sans-serif;
  font-size: 24px;
  font-weight: bold;

  ${'' /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */}
`;

export const TituloID = styled.div`
  text-align: center;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: #114396;

`;

export const ContenedorDatos = styled.div`
  padding: 25px 15px;
  text-align: center;

`;

export const ContedorWigdets = styled.div`
  display: inline-block;
`;

export const ContenedorI = styled.img`
  width: 100%;
  max-width: 150px; /* El ancho máximo que deseas establecer */
  height: auto; 
`;