import React from "react";
import { Bar } from "react-chartjs-2";
import { Actalizacion, Contenedor, ContenedorWa, IconoEdit, ImagenWid, IndicadorT, Main, NombreR, NombreD, StyledReactGridItem, Testing, Titulo } from './ElementsDisplay';
import EditIcon from '@material-ui/icons/Edit';

const BarChart = (props) => {
  const nombreWidget = props.info.tituloWidget || false
  const nombeDiposositivo = props.info.viwes.Mnombre[0] || false
  const idDispositivo = props.info.viwes.Mdevice[0] || false
  const background = props.info.background || "#ffffff"
  const {año, hora} = props?.funcionF(props.info.ultimoDato)
  const info = props.info
  const index = props.index

  const adjustedEjex = props.data[0].x.map((fecha) => {
    const date = new Date(fecha);
    return date.toISOString();
  });

  const chartOptions = {
    maintainAspectRatio: false,
    title: {
      display: false,
      text: props.nombre,
    }
  };
  const data = {
    labels: adjustedEjex,
    datasets: [
      {
        label: nombreWidget,
        backgroundColor: "rgba(255,99,132,0.2)",
        borderColor: "rgba(255,99,132,1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255,99,132,0.4)",
        hoverBorderColor: "rgba(255,99,132,1)",
        data: props.data[0].y,
      },
    ],
  };

  function handleMouseOver(event) {
    event.target.style.cursor = 'pointer';
  }

  return (
    <>

        

        
      
    {Object.keys(props.data[0].x).length>0&&Object.keys(props.data[0].y).length>0 ? <>
    <Main background={background} className="widgets-font">
        <IconoEdit>
            <EditIcon onClick={()=>props.funcionE({index: index, widget: info})} onMouseOver={handleMouseOver}/>
        </IconoEdit>

        {nombreWidget != false ? <Titulo>{nombreWidget}</Titulo> : null}
        {nombeDiposositivo ? <NombreR>{info.nombre}</NombreR> : null}
        {idDispositivo ? <NombreR style={{top:"30px",right:"20px", position:"absolute"}}>{idDispositivo}</NombreR> : null}
      <Bar
      data={data}
      options={chartOptions}
      />
      <Actalizacion className='widgets-font'>Ultima actualizacion: {año} {hora}</Actalizacion>
    </Main>
    </> : <p>No hay Datos</p>}
    </>


 
  );
};
export default BarChart;
