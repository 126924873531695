import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Api from "../../services/api-service";
import { useLocation } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import { Main, ContenedorTitulo, ContenedorFechas } from "./ElementosStyles";
import "./ViewStatus.css"; // Importar el archivo CSS personalizado
import jsPDF from "jspdf"; // Importar jsPDF para generar el reporte en PDF
import "jspdf-autotable"; // Importar la extensión de autotable para jsPDF
import XLSX from "xlsx";

const ViewStatus = () => {
    const history = useHistory();
    const query = new URLSearchParams(useLocation().search);
    const organizacionn = query.get("organizacion");
    const localizacionn = query.get("localizacion");
    const [data, setData] = useState([]);
    const [fechaGeneracion, setFechaGeneracion] = useState(null);
    const [sirenasFuncionales, setSirenasFuncionales] = useState(0);
    const [sirenasNoFuncionales, setSirenasNoFuncionales] = useState(0);

    const centeredButtonStyle = {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '20px',
    };

    const atras = () => {
        history.goBack();
    };

    useEffect(() => {
        // Restablecer el estado cuando cambia la localización
        setData([]);
        setFechaGeneracion(null);
        setSirenasFuncionales(0);
        setSirenasNoFuncionales(0);
    }, [localizacionn]);

    const obtenerData = async () => {
        try {
            const Documento = {
                organizacion: organizacionn,
                localizacion: localizacionn,
            };
            let resultado
            if (localizacionn === "sto_epconst_loca_1") {
                resultado = await Api.ubicacionDispo(organizacionn, localizacionn, "epwt32_01")
                const funcionales = resultado.data.filter((item) => item.reportado).length;
                const noFuncionales = resultado.data.length - funcionales;
                setSirenasFuncionales(funcionales);
                setSirenasNoFuncionales(noFuncionales);
                setData(resultado.data);
            } else if (localizacionn === "sto_epconst_loca_2") {
                resultado = await Api.viweStatusSiren(Documento);
                setData(resultado);
                const funcionales = resultado.filter((item) => item.reportado).length;
                const noFuncionales = resultado.length - funcionales;
                setSirenasFuncionales(funcionales - 43);
                setSirenasNoFuncionales(noFuncionales);
            }
            console.log(resultado)
            setFechaGeneracion(new Date().toLocaleString()); // Establecer la fecha de generación

        } catch (error) {
            console.error("Error al obtener data:", error);
        }
    };

    const renderEstado = (estado) => {
        return estado ? "Reportó" : "No Funcionó";
    };

    const handleExportExcel = () => {
        const dataExcel = data.map(item => ({
            id: localizacionn == "sto_epconst_loca" ? item.idSirena : item.id,
            nombre: item.nombre,
            reportado: item.reportado ? 'Operativa' : 'Desconectada',
            latitud: item.ubicacion.coordinates[0],
            longitud: item.ubicacion.coordinates[1]
        }));
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(dataExcel);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Tabla");
        XLSX.writeFile(workbook, "tabla.xlsx");
    };

    const generarReportePDF = () => {
        const doc = new jsPDF();
        doc.text("Reporte de Estado de Sirenas", 20, 10);
        doc.text(`Fecha de Generación: ${fechaGeneracion}`, 20, 20);
        doc.text(`Sirenas Funcionales: ${sirenasFuncionales}`, 20, 30);
        doc.text(`Sirenas No Funcionales: ${sirenasNoFuncionales}`, 20, 40);

        const tableColumn = ["Nombre", "idSirena", "Estado"];
        const tableRows = data.map((item) => [
            item.nombre,
            item.idSirena,
            renderEstado(item.reportado),
        ]);

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            startY: 50,
            styles: {
                cellWidth: 'wrap',
                fillColor: (rowIndex, node, column) => {
                    return node.raw[column.dataKey] === "No Funcionó" ? [255, 0, 0] : [255, 255, 255];
                },
                textColor: (rowIndex, node, column) => {
                    return node.raw[column.dataKey] === "No Funcionó" ? [255, 255, 255] : [0, 0, 0];
                },
            },
        });

        doc.save("reporte_sirenas.pdf");
    };

    const generarReportePDFGabinete = () => {
        // Filtrar dispositivos con fallas, dispositivos que no reportaron y dispositivos que reportan bien
        let dispositivosConFallas = data.filter(dispositivo => dispositivo.puertaAbierta || dispositivo.sinEnergia);
        let dispositivosNoReportados = data.filter(dispositivo => !dispositivo.reportado);
        let dispositivosReportandoBien = data.filter(dispositivo => dispositivo.reportado && !dispositivo.puertaAbierta && !dispositivo.sinEnergia);

        let totalDispositivos = data.length;
        let dispositivosConFallasCount = dispositivosConFallas.length;
        let dispositivosNoReportadosCount = dispositivosNoReportados.length;
        let dispositivosReportandoBienCount = dispositivosReportandoBien.length;

        const doc = new jsPDF();
        let y = 20; // Inicializa la posición Y para el texto

        // Título del documento
        doc.setFontSize(16);
        doc.text('Reporte de Dispositivos', 105, y, null, null, 'center');
        y += 10; // Incrementa el valor de Y para la siguiente línea

        // Fecha del reporte
        doc.setFontSize(12);
        doc.text(`Fecha del reporte: ${new Date().toLocaleString()}`, 180, y, null, null, 'right');
        y += 10; // Incrementa el valor de Y para la siguiente línea

        // Resumen
        doc.setFontSize(14);
        doc.text('Resumen:', 20, y);

        // Subrayado para Resumen
        let textWidth = doc.getTextWidth('Resumen:');
        doc.setLineWidth(0.4);
        doc.line(20, y + 2, 20 + textWidth, y + 2);  // Dibuja una línea debajo del texto
        y += 10;

        doc.setFontSize(12);
        doc.text(`Total de dispositivos: ${totalDispositivos}`, 20, y);
        y += 5;
        doc.text(`Cantidad de dispositivos que reportan bien: ${dispositivosReportandoBienCount}`, 20, y);
        y += 5;
        doc.text(`Cantidad de dispositivos con fallas: ${dispositivosConFallasCount}`, 20, y);
        y += 5;
        doc.text(`Cantidad de dispositivos que no reportan dentro de 20 minutos: ${dispositivosNoReportadosCount}`, 20, y);
        y += 10; // Espacio para separar las secciones

        // Detalles de Dispositivos con Fallas
        doc.setFontSize(14);
        doc.text('Detalles de Dispositivos con Fallas:', 20, y);

        // Subrayado para Detalles de Dispositivos con Fallas
        textWidth = doc.getTextWidth('Detalles de Dispositivos con Fallas:');
        doc.setLineWidth(0.4);
        doc.line(20, y + 2, 20 + textWidth, y + 2);  // Dibuja una línea debajo del texto
        y += 10;

        doc.setFontSize(12);
        dispositivosConFallas.forEach((dispositivo, index) => {
            if (y > 270) { // Si el contenido se acerca al final de la página, crea una nueva
                doc.addPage();
                y = 20;
            }
            doc.text(`ID Dispositivo: ${dispositivo.deviceID}`, 20, y);
            y += 5;
            doc.text(`ID: ${dispositivo.id}`, 20, y);
            y += 5;

            if (dispositivo.puertaAbierta) {
                doc.text(`Error: Puerta abierta`, 20, y);
                y += 5;
            }
            if (dispositivo.sinEnergia) {
                doc.text(`Error: Sin energía`, 20, y);
                y += 5;
            }
            y += 5;
        });

        // Detalles de Dispositivos que no Reportan dentro de 20 minutos
        doc.setFontSize(14);
        doc.text('Detalles de Dispositivos que no Reportan dentro de 20 minutos:', 20, y);

        // Subrayado para Detalles de Dispositivos que no Reportan dentro de 20 minutos
        textWidth = doc.getTextWidth('Detalles de Dispositivos que no Reportan dentro de 20 minutos:');
        doc.setLineWidth(0.4);
        doc.line(20, y + 2, 20 + textWidth, y + 2);  // Dibuja una línea debajo del texto
        y += 10;

        doc.setFontSize(12);
        dispositivosNoReportados.forEach((dispositivo, index) => {
            if (y > 270) { // Si el contenido se acerca al final de la página, crea una nueva
                doc.addPage();
                y = 20;
            }
            doc.text(`ID Dispositivo: ${dispositivo.deviceID}`, 20, y);
            y += 5;
            doc.text(`ID: ${dispositivo.id}`, 20, y);
            y += 5;
            doc.text(`No reportó dentro de los 20 minutos`, 20, y);
            y += 10;
        });

        // Detalles de Dispositivos que Reportan Bien
        doc.setFontSize(14);
        doc.text('Detalles de Dispositivos que Reportan Bien:', 20, y);

        // Subrayado para Detalles de Dispositivos que Reportan Bien
        textWidth = doc.getTextWidth('Detalles de Dispositivos que Reportan Bien:');
        doc.setLineWidth(0.4);
        doc.line(20, y + 2, 20 + textWidth, y + 2);  // Dibuja una línea debajo del texto

        y += 10; // Espacio para la siguiente sección

        doc.setFontSize(12);
        dispositivosReportandoBien.forEach((dispositivo, index) => {
            if (y > 270) { // Si el contenido se acerca al final de la página, crea una nueva
                doc.addPage();
                y = 20;
            }
            doc.text(`ID Dispositivo: ${dispositivo.deviceID}`, 20, y);
            y += 5;
            doc.text(`ID: ${dispositivo.id}`, 20, y);
            y += 5;
            doc.text(`Reportando correctamente`, 20, y);
            y += 10;
        });

        // Guarda el PDF
        doc.save('reporte_dispositivos.pdf');
    };

    return (
        <Main>
            <ContenedorFechas>
                <Button onClick={atras}>Atras</Button>
            </ContenedorFechas>
            <ContenedorTitulo>Verificar estado dispositivos</ContenedorTitulo>

            <div style={centeredButtonStyle}>
                <Button className="buttonGeneral" onClick={obtenerData}>
                    Comenzar
                </Button>
            </div>

            {fechaGeneracion && (<div>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <Button onClick={() => {
                        if (localizacionn === "sto_epconst_loca_2") {
                            generarReportePDF();
                        } else {
                            generarReportePDFGabinete();
                        }
                    }}>Generar Reporte PDF</Button>
                    <p>  </p>
                    <Button onClick={handleExportExcel}>Generar Reporte Excel</Button>
                </div>
                <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <strong>Fecha de Generación:</strong> {fechaGeneracion}
                </div>
            </div>

            )}

            {data.length > 0 && (
                <>
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <strong>Dispositivos Funcionales:</strong> {sirenasFuncionales}
                        {localizacionn === "sto_epconst_loca_2" ?
                            <div>
                                <p>{'Nota: No incluye sirenas sin monitoreo (43 sirenas)'}</p>
                            </div>
                            : null}
                    </div>
                    <div style={{ textAlign: 'center', marginTop: '10px' }}>
                        <strong>Dispositivos No Funcionales:</strong> {sirenasNoFuncionales}
                    </div>
                    <Table className="custom-table" style={{ marginTop: '20px' }}>
                        <thead>
                            <tr>
                                <th>Nombre</th>
                                <th>ID dispositivo</th>
                                <th>Estado</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item) => (
                                <tr key={item._id}>
                                    <td>{item.nombre}</td>
                                    <td>{localizacionn === "sto_epconst_loca_2" ? item.idSirena : item.id}</td>
                                    <td>{renderEstado(item.reportado)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                </>
            )}
        </Main>
    );
};

export default ViewStatus;