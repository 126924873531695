import React, { useEffect, useState } from "react";
import "./TablePagination.css";
import XLSX from "xlsx";
import { useLocation } from "react-router-dom";
import { Button, Card, Row, Col } from "react-bootstrap";
import { ContenedorDatos, Main } from "../ElementosStyles";
// import { FaMapLocation } from "react-icons/fa";
import EditIcon from "@material-ui/icons/Edit";
import { FcOvertime } from "react-icons/fc";
import { GiChart } from "react-icons/gi";
import { FaMapMarkedAlt, FaBullhorn, FaEye } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Tooltip from "react-tooltip";

const TablePagination = ({
  data,
  itemsPerPageOptions,
  columnValues,
  setShowEditNomb,
  tipoDispositivo,
}) => {
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const obteOrganizacion = query.get("organizacion");
  const localizacion = query.get("localizacion");
  const ruta = location.pathname + location.search;
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(itemsPerPageOptions[0]);
  const [orderBy, setOrderBy] = useState({ column: "", orderType: "" });
  const history = useHistory();

  useEffect(() => {
    const storedPage = sessionStorage.getItem(`page_${ruta}`);
    const storedItemsPerPage = sessionStorage.getItem(`itemsPerPage_${ruta}`);
    if (storedPage) {
      setCurrentPage(parseInt(storedPage));
    } else {
      setCurrentPage(1);
    }
    if (storedItemsPerPage) {
      setItemsPerPage(parseInt(storedItemsPerPage));
    } else {
      setItemsPerPage(itemsPerPageOptions[0]);
    }
  }, [ruta]);

  const handleItemsPerPageChange = (event) => {
    const perPage = parseInt(event.target.value);
    setItemsPerPage(perPage);
    setCurrentPage(1);
    sessionStorage.setItem(`itemsPerPage_${ruta}`, perPage);
  };

  const setCurrent = (value) => {
    setCurrentPage(value);
    sessionStorage.setItem(`page_${ruta}`, value);
  };

  const handleExportExcel = () => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tabla");
    XLSX.writeFile(workbook, "tabla.xlsx");
  };

  const handleSort = (column) => {
    if (orderBy.column === column) {
      setOrderBy((prevOrderBy) => ({
        column: prevOrderBy.column,
        orderType: prevOrderBy.orderType === "asc" ? "desc" : "asc",
      }));
    } else {
      setOrderBy({ column: column, orderType: "asc" });
    }
  };

  const renderTableHeader = () => {
    return (
      <thead>
        <tr>
          {columnValues.map((columnValue) => (
            <th
              key={columnValue.nValor}
              onClick={() => handleSort(columnValue.nValor)}
              className={orderBy.column === columnValue.nValor ? "sorted" : ""}
            >
              {columnValue.nColumna}{" "}
              {orderBy.column === columnValue.nValor &&
                (orderBy.orderType === "asc" ? "▲" : "▼")}
            </th>
          ))}
        </tr>
      </thead>
    );
  };

  const sortedData = data.sort((a, b) => {
    const column = orderBy.column;
    const orderType = orderBy.orderType === "asc" ? 1 : -1;

    if (a[column] < b[column]) {
      return -1 * orderType;
    }
    if (a[column] > b[column]) {
      return 1 * orderType;
    }
    return 0;
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(sortedData.length / itemsPerPage);

  const renderPaginationNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    let startPage, endPage;
    if (totalPages <= maxVisiblePages) {
      startPage = 1;
      endPage = totalPages;
    } else {
      const maxVisiblePagesBeforeCurrent = Math.floor(maxVisiblePages / 2);
      const maxVisiblePagesAfterCurrent = Math.ceil(maxVisiblePages / 2) - 1;

      if (currentPage <= maxVisiblePagesBeforeCurrent) {
        startPage = 1;
        endPage = maxVisiblePages;
      } else if (currentPage + maxVisiblePagesAfterCurrent >= totalPages) {
        startPage = totalPages - maxVisiblePages + 1;
        endPage = totalPages;
      } else {
        startPage = currentPage - maxVisiblePagesBeforeCurrent;
        endPage = currentPage + maxVisiblePagesAfterCurrent;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <>
        {startPage > 1 && (
          <>
            <span onClick={() => setCurrent(1)} className="pagination-number">
              1
            </span>
            {startPage > 2 && <span className="ellipsis">..</span>}
          </>
        )}
        {pageNumbers.map((number) => (
          <span
            key={number}
            onClick={() => setCurrent(number)}
            className={`pagination-number ${
              currentPage === number ? "active" : ""
            }`}
          >
            {number}
          </span>
        ))}
        {endPage < totalPages && (
          <>
            {endPage < totalPages - 1 && <span className="ellipsis">..</span>}
            <span
              onClick={() => setCurrent(totalPages)}
              className="pagination-number"
            >
              {totalPages}
            </span>
          </>
        )}
      </>
    );
  };

  const abrirMapa = (item) => {
    const latitud = item.ubicacion.coordinates[0];
    const longitud = item.ubicacion.coordinates[1];
    history.push(
      `/admin/mapa?organizacion=${obteOrganizacion}&localizacion=${localizacion}&lat=${latitud}&long=${longitud}`
    );
  };

  const monitorear = (e) => {
    let valor;
    if (localizacion === "sto_epconst_loca_2") {
      let nuevaCadena = e.replace("sto/", "");
      valor = nuevaCadena;
    } else {
      valor = e;
    }
    history.push(
      `/admin/viewDevicewt32?id=${valor}&organizacion=${obteOrganizacion}&localizacion=${localizacion}`
    );
  };

  const clicActualizarNombre = (nombre) => {
    setShowEditNomb(nombre);
  };

  const historico = (nombre) => {
    let partes = nombre.split("-");
    let parte1 = partes[0];
    let parte2;
    if (localizacion === "sto_epconst_loca_1") {
      parte2 = partes[2];
    } else {
      parte2 = partes[1];
    }
    history.push(
      `/admin/historico?organizacion=${obteOrganizacion}&localizacion=${localizacion}&deviceID=${parte2}&tipo=${tipoDispositivo}&nombre=${parte1}`
    );
  };

  const historicoUArtes = (nombre, catalogo, deviceID) => {
    history.push(
      `/admin/historico?organizacion=${obteOrganizacion}&localizacion=${localizacion}&deviceID=${deviceID}&tipo=${catalogo}&nombre=${nombre}`
    );
  };

  const desconexiones = (deviceID) => {
    history.push(
      `/admin/historicoSirena?organizacion=${obteOrganizacion}&localizacion=${localizacion}&deviceID=${deviceID}`
    );
  };

  return (
    <div style={{ margin: "0 10px" }}>
      <div style={{ overflow: "hidden", display: "block", flexDirection: "column" }}>
        <div className="table-container-overflow">
          <table className="table">
            {renderTableHeader()}
            <tbody>
              {currentItems.map((item, index) => (
                <tr key={index}>
                  {columnValues.map((columnValue) => {
                    if (
                      columnValue.nValor === "fechaCompleta" ||
                      columnValue.nValor === "fechaCreacion" ||
                      columnValue.nValor === "fecha" ||
                      columnValue.nValor === "ultimoDato"
                    ) {
                      return (
                        <td key={columnValue.nValor}>
                          {item[columnValue.nValor]
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("/") +
                            " " +
                            item[columnValue.nValor].split("T")[1].split(":")[0] +
                            ":" +
                            item[columnValue.nValor].split("T")[1].split(":")[1]}
                        </td>
                      );
                    } else if (columnValue.nValor === "doorStatus") {
                      if (item[columnValue.nValor]) {
                        return <td key={columnValue.nValor}>Cerrado</td>;
                      } else {
                        return <td key={columnValue.nValor}>Abierto</td>;
                      }
                    } else if (columnValue.nValor === "puerta") {
                      if (item[columnValue.nValor]) {
                        return <td key={columnValue.nValor}>Abierto</td>;
                      } else {
                        return <td key={columnValue.nValor}>Cerrado</td>;
                      }
                    } else if (columnValue.nValor === "energia") {
                      if (item[columnValue.nValor]) {
                        return <td key={columnValue.nValor}>Con energía</td>;
                      } else {
                        return <td key={columnValue.nValor}>Sin energía</td>;
                      }
                    } else if (columnValue.nValor === "door") {
                      if (item[columnValue.nValor]) {
                        return <td key={columnValue.nValor}>Abierto</td>;
                      } else {
                        return <td key={columnValue.nValor}>Cerrado</td>;
                      }
                    } else if (columnValue.nValor === "energy") {
                      if (item[columnValue.nValor]) {
                        return <td key={columnValue.nValor}>Sin energía</td>;
                      } else {
                        return <td key={columnValue.nValor}>Con energía</td>;
                      }
                    } else if (columnValue.nValor === "touchingWall") {
                      if (item[columnValue.nValor]) {
                        return <td key={columnValue.nValor}>Ok</td>;
                      } else {
                        return <td key={columnValue.nValor}>Fuera</td>;
                      }
                    } else if (columnValue.nValor === "movementStatus") {
                      if (item[columnValue.nValor]) {
                        return <td key={columnValue.nValor}>Sin movimiento</td>;
                      } else {
                        return <td key={columnValue.nValor}>Con movimiento</td>;
                      }
                    } else if (columnValue.nValor === "acciones") {
                      return (
                        <td>
                          <div
                            style={{
                              display: "grid",
                              gridTemplateColumns: "repeat(4, 1fr)",
                              gridGap: "0.1px",
                            }}
                          >
                            {obteOrganizacion === "uArtes" ? (
                              <>
                                <Tooltip
                                  id="historico-tooltip"
                                  place="top"
                                  effect="solid"
                                >
                                  Ver histórico
                                </Tooltip>
                                <GiChart
                                  size={"24px"}
                                  data-tip
                                  data-for="historico-tooltip"
                                  color="#6DC6F6"
                                  onClick={() =>
                                    historicoUArtes(
                                      item.nombre,
                                      item.catalogo,
                                      item.deviceID
                                    )
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <Tooltip
                                  id="mapa-tooltip"
                                  place="top"
                                  effect="solid"
                                >
                                  Ver ubicación
                                </Tooltip>
                                <FaMapMarkedAlt
                                  size={"24px"}
                                  data-tip
                                  data-for="mapa-tooltip"
                                  color="#53EE79"
                                  onClick={() => abrirMapa(item)}
                                  style={{ cursor: "pointer" }}
                                />

                                <Tooltip
                                  id="monitorear-tooltip"
                                  place="top"
                                  effect="solid"
                                >
                                  Monitorear
                                </Tooltip>
                                <FaBullhorn
                                  size={"24px"}
                                  data-tip
                                  data-for="monitorear-tooltip"
                                  color={
                                    item.typeSiren === "discador"
                                      ? "#CCCCCC"
                                      : "#DB4929"
                                  }
                                  onClick={
                                    item.typeSiren === "discador"
                                      ? null
                                      : () => monitorear(item.nombre)
                                  }
                                  style={{
                                    cursor:
                                      item.typeSiren === "discador"
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                />

                                <Tooltip
                                  id="historico-tooltip"
                                  place="top"
                                  effect="solid"
                                >
                                  Ver histórico
                                </Tooltip>
                                <GiChart
                                  size={"24px"}
                                  data-tip
                                  data-for="historico-tooltip"
                                  color={
                                    item.typeSiren === "discador"
                                      ? "#CCCCCC"
                                      : "#6DC6F6"
                                  }
                                  onClick={
                                    item.typeSiren === "discador"
                                      ? null
                                      : () =>
                                          historico(
                                            item.nombre +
                                              "-" +
                                              item.idSirena +
                                              "-" +
                                              item.deviceID
                                          )
                                  }
                                  style={{
                                    cursor:
                                      item.typeSiren === "discador"
                                        ? "not-allowed"
                                        : "pointer",
                                  }}
                                />

                                <Tooltip
                                  id="editar-tooltip"
                                  place="top"
                                  effect="solid"
                                >
                                  Editar dispositivo
                                </Tooltip>
                                <EditIcon
                                  data-tip
                                  data-for="editar-tooltip"
                                  style={{ color: "#A7A7A5", cursor: "pointer" }}
                                  onClick={() => clicActualizarNombre(item)}
                                />
                              </>
                            )}
                          </div>
                        </td>
                      );
                    } else if (columnValue.nValor === "verDesco") {
                      return (
                        <td>
                          <>
                            <Tooltip
                              id="desco-tooltip"
                              place="top"
                              effect="solid"
                            >
                              Ver Desconexiones
                            </Tooltip>
                            <FaEye
                              size={"24px"}
                              data-tip
                              data-for="desco-tooltip"
                              color="#D9B59C"
                              style={{ cursor: "pointer" }}
                              onClick={() => desconexiones(item.idSirena)}
                            />
                          </>
                        </td>
                      );
                    } else if (columnValue.nValor === "revisado") {
                      return (
                        <td key={columnValue.nValor}>
                          {item[columnValue.nValor].toString()}
                        </td>
                      );
                    } else {
                      return (
                        <td key={columnValue.nValor}>
                          {item[columnValue.nValor]}
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="pagination">
          <div className="items-per-page">
            <label>Ver por página:</label>
            <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
              {itemsPerPageOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className="pagination-numbers">{renderPaginationNumbers()}</div>
        </div>
      </div>
      <ContenedorDatos>
        <Button className="buttonGeneral" onClick={handleExportExcel}>
          Exportar a Excel
        </Button>
      </ContenedorDatos>
    </div>
  );
};

export default TablePagination;
