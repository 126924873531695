import React from "react";
import { Line } from "react-chartjs-2";
import { Resizable } from "react-resizable";
import "chartjs-adapter-moment";
import { Contenedor, Main } from "../LineChart/ElementsDisplay";

const LineTest = ({ info }) => {
  let options;
  let dataChart;

  console.log("desconexiones")

  if (info.length === 0) {
    return (
      <>
        <p>No hay Datos</p>
      </>
    );
  } else {
    const data = [];
    const labels = [];

    for (let i = 0; i < info.length; i++) {
      let estado;
      const fechaCompleta = new Date(info[i].fechaCompleta);
      fechaCompleta.setHours(fechaCompleta.getHours() + 5);

      if (i !== info.length - 1) {
        const diffTime = (new Date(info[i].fechaCompleta) - new Date(info[i + 1].fechaCompleta)) / (1000 * 60);
        estado = diffTime < 15 ? 1 : 0;
      }
      data.push(estado);
      labels.push(fechaCompleta);
    }

    options = {
      scales: {
        x: {
          type: "time",
          time: {
            unit: "hour",
            tooltipFormat: "ll HH:mm",
          },
        },
        y: {
          beginAtZero: true,
          reverse: false,
        },
      },
      maintainAspectRatio: false,
      plugins: {
        tooltip: {
          callbacks: {
            label: (context) => {
              const estado = context.raw === 0 ? "Retorna la conexión" : "Conectada";
              return `Estado: ${estado}`;
            },
          },
        },
      },
    };

    dataChart = {
      labels: labels,
      datasets: [
        {
          label: "Conexion de la Sirena",
          data: data,
          fill: true,
          borderWidth: 2,
          pointRadius: 2,
          backgroundColor: "rgba(75,192,192,0.2)",
          borderColor: "rgba(75,192,192,1)",
          stepped: true,
        },
      ],
    };
  }

  return (
    <>
      {info.length > 0 ? (
        <Main>
          <Contenedor>
            <Resizable>
              <Line data={dataChart} options={options} />
            </Resizable>
          </Contenedor>
        </Main>
      ) : (
        <p>No hay Datos</p>
      )}
    </>
  );
};

export default LineTest;
