import React, { useState, useEffect } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import io from "socket.io-client";

const styleMapa = {
  width: "50%",
  height: "100%",
  position: "absolute",
  left: 0,
};

const containerStyle = {
  position: "relative",
  width: "100%",
  height: "100vh",
};

function generateRandomColor() {
  const randomColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
  return randomColor;
}

function EnhancedTable(props) {
  const [temperatureDataList, setTemperatureDataList] = useState([]);
  const [mapCenter, setMapCenter] = useState({
    lat: -0.115079,
    lng: -78.450037,
  });
  const [zoom, setZoom] = useState(8); // Estado para controlar el zoom

  useEffect(() => {
    const socket = io.connect("https://aiot.constecoin.com");

    socket.on("Temperature", (data) => {
      const newData = { ...data, id: Date.now(), color: generateRandomColor() };
      setTemperatureDataList((prevList) => [...prevList, newData]);

      setTimeout(() => {
        setTemperatureDataList((prevList) =>
          prevList.filter((item) => item.id !== newData.id)
        );
      }, data.tiempo);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const moveToLocation = (lat, lng) => {
    setMapCenter({ lat, lng });
    setZoom(19); // Actualiza el zoom a un valor más cercano
  };

  const renderTemperatureDataList = () => {
    return temperatureDataList.map((data) => {
      const dynamicBoxStyle = {
        background: "white",
        padding: "20px",
        borderRadius: "10px",
        boxShadow: "0 2px 4px rgba(0,0,0,0.2)",
        marginBottom: "20px",
        borderLeft: `5px solid ${data.color}`,
        cursor: "pointer", // Estilo para hacer el cursor tipo pointer
      };

      const cleanId = data.deviceID.replace("Device ID: ", "");
      const parts = cleanId.split("/");
      const prefix = parts[0];
      const sirenaNumber = parseInt(parts[1], 10);

      return (
        <div
          key={data.id}
          style={dynamicBoxStyle}
          onClick={() => moveToLocation(data.latitud, data.longitud)}
        >
          {prefix === "sto" ? (
            <p style={{ margin: "5px 0" }}>Ciudad: Santo Domingo</p>
          ) : null}
          <p style={{ margin: "5px 0" }}>Número Sirena: {sirenaNumber}</p>
          <p style={{ margin: "5px 0" }}>Modo Activacion: {data.modo}</p>
          <p style={{ margin: "5px 0" }}>Fecha: {data.fechaCompleta}</p>
        </div>
      );
    });
  };

  return (
    <div style={containerStyle}>
      <Map
        google={props.google}
        zoom={zoom}
        style={styleMapa}
        initialCenter={mapCenter} // Usa las coordenadas iniciales aquí
        center={mapCenter}
      >
        {temperatureDataList.map((data) => (
          <Marker
            key={data.id}
            position={{ lat: data.latitud, lng: data.longitud }}
            icon={{
              url: `data:image/svg+xml;utf-8, \
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24"> \
  <path fill="${encodeURIComponent(
    data.color
  )}" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5S10.62 6.5 12 6.5 14.5 7.62 14.5 9 13.38 11.5 12 11.5z"/> \
</svg>`,
            }}
          />
        ))}
      </Map>
      <div
        style={{
          width: "50%",
          height: "100%",
          overflowY: "scroll",
          position: "absolute",
          right: 0,
          padding: "20px",
        }}
      >
        {renderTemperatureDataList()}
      </div>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBYNDUEf0g8OQPJfiEW7ybizYPiMN_7XOU", // Reemplaza con tu propia clave de API de Google Maps
})(EnhancedTable);
