import styled from "styled-components";

export const Main = styled.div`
  margin: 25px 30px 25px 30px;
  height: 100%;
  ${"" /* background: red; */}
`;
export const ContenedorTitulo = styled.h2`
  text-align: center;
  padding: 10px 15px;
  height: 45px;
`;
export const Titulo = styled.div``;
export const ContenedorFechas = styled.div`
    margin-right: 15px;
    margin-top: 9px;
    display: inline-block;
`;

export const ContenedorF = styled.div`
    margin-right: 15px;
    margin-top: 9px;
    display: inline-block;
    width: 200px
`;

export const Categorias = styled.div`
  display: inline-block;
  margin-left: 25px;
`;
export const ContenedorTabla = styled.div``;
export const NavbarTabla = styled.div`
  ${"" /* width: 100%; */}
  margin: 30px 15px;
  ${"" /* background: #254d8f; */}
  ${"" /* text-align: center; */}
`;

export const TituloDispositivo = styled.div`
  text-align: center;
  font-family: "Arial", sans-serif;
  font-size: 24px;
  font-weight: bold;

  ${'' /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */}
`;

export const TituloID = styled.div`
  text-align: center;
  font-family: "Arial", sans-serif;
  font-size: 16px;
  color: #114396;

`;

export const ContenedorDatos = styled.div`
  padding: 25px 15px;

`;

export const ContedorWigdets = styled.div`
  display: inline-block;
`;

export const ContenedorI = styled.img`
    width: 100%;
  max-width: 150px; /* El ancho máximo que deseas establecer */
  height: auto; 
`;

export const Opciones = styled.div`
padding: 13px 0px 0px 0px;
display: inline-block;
`;

export const ContenedorFiltros = styled.div`
display: inline-block;
justify-content: start;
margin: 5px 5px 5px 0;
`

export const CTituloModal = styled.div`
padding: 15px 15px 15px 15px;
background: #1976d2;
`;

export const TituloModal = styled.h2`
  text-align: center;
  color: white;
`;

export const OpcionesModal = styled.div`
padding: 13px 0px 13px 0px;
  ${'' /* margin: 10px; */}
`;

export const BotonCrear = styled.button`
  display: inline-block;
  float: right;
  margin: 10px;
  background: #114396;
  color: white;
  border-radius: 5px;
  height: 49.999996185302734px;
  border: none;
  width: 210px;
  text-align: center;
`;

export const ContenedorButton = styled.div`
  padding: 25px 0;
  text-align: center;
`;

export const BotonDevice = styled.button`
  display: inline-block;
  margin: 10px;
  background: #114396;
  color: white;
  border-radius: 5px;
  height: 49.999996185302734px;
  border: none;
  width: 150px;

  text-align: center;
`;

