import React, { useState, useEffect } from 'react';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import API from "../../services/api-service";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import BarChart from "../Historico/Widgets/BarChart/index";
import BarChartTime from "../Historico/Widgets/BarChart/timeDesconexion";
import LineChart from "../Historico/Widgets/LineChartEstados/desconexiones";

import {
	Main,
	ContenedorTitulo,
	ContenedorFechas,
	TituloDispositivo,
	Tarjeta,
	ContenedorDatos
} from "../Historico/ElementosStyles";

const ViewDesconexion = () => {
	const history = useHistory();
	const [controladorC, setControladorC] = useState(false);
	const [fechaInicial, setFechaInicial] = useState(new Date().getTime() - 1000 * 60 * 60 * 24);
	const [fechaFinal, setFechaFinal] = useState(new Date().getTime());
	const query = new URLSearchParams(useLocation().search);
	const id = query.get("deviceID");
	const [data, setData] = useState([])

	useEffect(async () => {
		try {
			let documento = {
				fechaInicial: fechaInicial,
				fechaFinal: fechaFinal,
				id: id
			}
			API.verDesconexion(documento).then(
				(respuesta) => {
					if (respuesta.length > 0) {
						setData(respuesta)
					}
				},
				(error) => { }
			);

		} catch (error) {
			console.log(error);
		}
	}, [id, controladorC])

	const cambiarFechaI = (fecha) => {
		setFechaInicial(fecha);
	};

	const cambiarFechaF = (fecha) => {
		setFechaFinal(fecha);
	};

	const FechaUltima = () => {
		setControladorC(!controladorC);
	};

	const atras = () => {
		history.goBack();
	};

	return (
		<Main>
			<ContenedorFechas>
				<Button onClick={atras}>Atras</Button>
			</ContenedorFechas>
			<ContenedorTitulo>Desconexiones sirena: {id}</ContenedorTitulo>
			<ContenedorFechas>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DateTimePicker
						renderInput={(props) => <TextField {...props} />}
						label="Fecha Inicial"
						value={fechaInicial}
						onChange={cambiarFechaI}
					/>
				</LocalizationProvider>
			</ContenedorFechas>
			<ContenedorFechas>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<DateTimePicker
						renderInput={(props) => <TextField {...props} />}
						label="Fecha Final"
						value={fechaFinal}
						onChange={cambiarFechaF}
					/>
				</LocalizationProvider>
			</ContenedorFechas>
			<ContenedorFechas>
				<Button
					className="buttonGeneral"
					onClick={FechaUltima}
					style={{
						margin: "0px 15px",
					}}
				>
					Buscar
				</Button>
			</ContenedorFechas>

			{data.length > 0 ? (
				<>
					<ContenedorDatos>
						<Tarjeta>
							<TituloDispositivo>Diagramas</TituloDispositivo>
							<BarChart info={data} />
							<LineChart info={data} />
							<BarChartTime data={data} />
						</Tarjeta>
					</ContenedorDatos>
				</>
			) : <p>Revisa el historico entre fechas</p>}

		</Main>
	);
};

export default ViewDesconexion;
