import React, { useState, useMemo } from 'react'
import TextField from "@mui/material/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Informa from "../../Informacion/index";
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Api from '../../../services/api-service.js';
import { SketchPicker as Picker } from 'react-color';
import AUTH from "../../../services/auth-service";
import ModalCentro from "../../../components/Modal/Modal Centro";
import Select1 from "../../../components/Selects/Select1";
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Swal from "sweetalert2";
import { ContenedorTitulo, Main, Opciones, BotonGuardar, ContenedorBotoness, ContenedorBotones } from '../Elements'
import { Back, BackD, CTituloModal, ContenedorSe, Contenido, TablaDispo, Th, Tr, TrC, ContenedorDis, Nombredispositivo, DescripcionDispo, Organizacion } from "../../../views/Dashboard/DashboardElements";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";

const Line = ({ data, valorWid, device, variable, DispositivoE, saveData, valorActual,
    funcionE, children,datoTabla, VerificarId,
    showVariables, selectedDevice, organizacionn, localizacionn, ConsumirDispositivo,
    setVariable,setDevice }) => {

    const [tituloWidget, setTituloWidget] = useState(valorActual.widget.tituloWidget)
    const [tiempoReporte, setTiempoReporte] = useState(valorActual.widget.reporte)
    const [backgroundColor, setBackgroudColor] = useState(valorActual.widget.background)

    const [showModall, setShowModall] = useState(false);
    const [iterador, setIterador] = useState(null);
    const [variablesSeleccionadas, setVariablesSeleccionadas] = useState();

    const [variableS, setVariables] = useState(valorActual.widget.variable);
    const [devices, setDevices] = useState(valorActual.widget.device);
    const [nombres, setNombres] = useState(valorActual.widget.nombre);
    const [Mdevice, setMdevice] = useState(valorActual.widget.viwes.Mdevice);
    const [Mnombre, setMnombre] = useState(valorActual.widget.viwes.Mnombre);

    const [Dispositivos, setDispositivos] = useState([]);
    const [listDispositivos, setListaDispositivos] = useState(valorActual.widget.typeDevice);

    const [funcionEjecutada, setFuncionEjecutada] = useState(true);

    const GuardarData = () => {
        let Views = {
            "Mdevice": false,
            "Mnombre": false
        }
        let maxY
        let cantidad
        let maxX
        if (Object.keys(data).length == 4) {
            maxY = 0
            cantidad = 1
            data.posiciones = []
            data.identificadores = []
        } else {
            maxY = data.posiciones[0].y;
            maxX = data.posiciones[0].x;
            cantidad = data.posiciones.length + 1;
            cantidad = cantidad.toString();

            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y > maxY) {
                    maxY = data.posiciones[i].y;
                }
            }
            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y == maxY) {
                    if (data.posiciones[i].x > maxX) {
                        maxX = data.posiciones[i].x;
                    }
                }
            }
        }

        if (maxX > 4) {
            maxX = 0
            maxY = maxY + 1
        } else {
            maxX = maxX + 1
        }

        let nombres = {
            name: valorWid,
            device: device,
            variable: variable,
            typeDevice: DispositivoE,
            reporte: Number(tiempoReporte)
        };


        if (tituloWidget != "") {
            nombres.tituloWidget = tituloWidget
        }        
        nombres.viwes = Views
        let newWidget = {
            w: 11,
            h: 2,
            x: maxX,
            y: maxY,
            i: cantidad,
            moved: false,
            static: false,
        };
        data.posiciones.push(newWidget);
        data.identificadores.push(nombres);
        saveData(data)

    };
    const VerificarTituloWidget = (e) => {
        const inputValue = e.target.value;
        setTituloWidget(inputValue)
    }       

    const enviarEliminar = () => {
        funcionE()
    }

    const submitForm = () => {
        if (data.identificadores[valorActual.index].tituloWidget != tituloWidget) {
            data.identificadores[valorActual.index].tituloWidget = tituloWidget
        }
        if (data.identificadores[valorActual.index].reporte != tiempoReporte) {
            data.identificadores[valorActual.index].reporte = tiempoReporte
        }
        if (data.identificadores[valorActual.index].background != backgroundColor) {
            data.identificadores[valorActual.index].background = backgroundColor.hex
        }
        if (data.identificadores[valorActual.index].variable != variableS){
            data.identificadores[valorActual.index].variable = variableS
        }
        if (data.identificadores[valorActual.index].device != devices ){
            data.identificadores[valorActual.index].device = devices
        }
        if (data.identificadores[valorActual.index].viwes.Mdevice != Mdevice){
            data.identificadores[valorActual.index].viwes.Mdevice = Mdevice
        }
        if (data.identificadores[valorActual.index].viwes.Mnombre != Mnombre){
            data.identificadores[valorActual.index].viwes.Mnombre = Mnombre
        }
        if (data.identificadores[valorActual.index].typeDevice != listDispositivos){
            data.identificadores[valorActual.index].typeDevice = listDispositivos
        }

        Api.actualizarWidget(data).then(
            result => {
                saveData()
            }, error => {
                alert(error)
            }
        )
    }

    const handleCloseModall = () => {
        setShowModall(false);
        setDispositivos([])
    };

    const decimalToHex = (alpha) =>
        alpha === 0 ? '00' : Math.round(255 * alpha).toString(16);

    const hexColor = useMemo(() => {
        if (typeof backgroundColor === 'string') {
            return backgroundColor;
        }
        return `${backgroundColor?.hex}${decimalToHex(backgroundColor?.rgb?.a)}`;
    }, [backgroundColor]);
    
    //testing
    const handleMostrarComponente = (index) => {  
             
        let organizaciones = AUTH.getOrganizaciones();
        organizaciones.map((index, i) => {
            if (index.organizacion == organizacionn) {
                index.localizacion.map((localizacion, j) => {
                    if (localizacion.localiza == localizacionn) {
                        let documento = {};
                        localizacion.configuraciones.map((confi, k) => {
                            documento.label = confi.nombre;
                            documento.value = confi.catalogo;
                            Dispositivos.push(documento);
                            documento = {}
                        });
                    }
                });
            }
        });        
        setShowModall(true)        
        setIterador(index)
    };

    const eliminarVariable = (index) => {
        const nuevasVarialeS = [...variableS];
        nuevasVarialeS.splice(index, 1);
        setVariables(nuevasVarialeS)

        const nuevosDispositivos = [...devices];
        nuevosDispositivos.splice(index, 1);
        setDevices(nuevosDispositivos)

        const nuevaListaDispositivos = [...listDispositivos];
        nuevaListaDispositivos.splice(index, 1);
        setListaDispositivos(nuevaListaDispositivos)
        setFuncionEjecutada(true)

        Mdevice.pop()
        Mnombre.pop()

    }

    const agregarVariables = () => {
        if(!funcionEjecutada){
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Selecciona una variable primero'                
              })    
        }else{
           
            setFuncionEjecutada(false)

            variableS.push(null)
            devices.push(null)
            Mdevice.push(true)
            Mnombre.push(true)
           
        }  
    };

     const handleVariableClick = (variable) => {        
        
        //setVariablesSeleccionadas([...variablesSeleccionadas, [variable, false, false]]);
        
        handleCloseModall();
        const partes = variable.split("-");
        const antes = partes[0];
        const despues = partes[1];
        const nombre = partes[2];
        if (nombre == "" || nombre == undefined || nombre == null) {
            nombre = despues
        }
        setVariable(antes)
        setDevice(despues)
        let resultado = {
            deviceID: despues,
            variable: antes,
            nombre: nombre
        };           
        
        variableS.splice(iterador,1,resultado.variable)
        devices.splice(iterador,1,resultado.deviceID)
        //nombres.splice(iterador,1,resultado.nombre)

        
        
        setFuncionEjecutada(true)
        setDispositivos([])
        setListaDispositivos(prevListDispositivos => [...prevListDispositivos, DispositivoE]);   
     };

    //termina testing
    return (
        <>
            <Main>
                <><div><h5>Dispostivo : {valorActual.widget.nombre} </h5></div>
                </>
                <ContenedorTitulo>
                    Parámetros
                </ContenedorTitulo>
                <TextField
                    label="Titulo Widget"
                    value={tituloWidget}
                    onChange={VerificarTituloWidget}
                />               
                <Opciones>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Tiempo</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={tiempoReporte}
                            label="Estado"
                            onChange={(e) => setTiempoReporte(e.target.value)}
                        >
                            <MenuItem value="1440">ultimas 24 horas</MenuItem>
                            <MenuItem value="10080">ultima semana</MenuItem>
                            <MenuItem value="43800">ultimo mes</MenuItem>
                            <MenuItem value="131400">ultimos tres meses</MenuItem>
                            <MenuItem value="525600">ultimo año</MenuItem>
                        </Select>
                    </FormControl>
                </Opciones>
                <Opciones>
                <Picker color={hexColor} onChange={setBackgroudColor} />
                </Opciones>
                <Opciones>
                    <Button variant="contained" onClick={agregarVariables}>
                        Agregar variable
                    </Button>
                </Opciones>
                <hr />

                {variableS.map((device, index) => (
                    <div key={index}>
                        <div><h5>ID : {devices[index]}</h5></div>
                        <div><h5>Variable : {variableS[index]} </h5></div>
                        <Opciones>
                            <Button variant="contained"  onClick={() => handleMostrarComponente(index)}>
                                Seleccionar variable
                            </Button>
                        </Opciones>
                        <Opciones>
                            <Button variant="outlined" color="error" onClick={() => eliminarVariable(index)}>
                                Eliminar Variable
                            </Button>
                        </Opciones>
                        {/* {Object.keys(variableS).length  > 0 ? (
                            <>
                                <h5>Seleccionó el dispositivo con la variable ({variableS[index]}) del dispositivo ({variableS[index].nombre}) con ID ({devices[index]}) </h5> 
                            </>
                        ) : null} */}
                        <hr />
                    </div>
                    
                ))}


                
                {children}
                <ContenedorBotones>
                    <ContenedorBotoness>
                        <Stack spacing={2} direction="row">
                            <Button variant="contained" onClick={submitForm}>
                                Actualizar
                            </Button>
                            <Button variant="outlined" color="error" onClick={enviarEliminar}>
                                Eliminar
                            </Button>
                        </Stack>
                    </ContenedorBotoness>
                </ContenedorBotones>

                <ModalCentro isOpen={showModall} onClose={handleCloseModall}>

                    <h4>Seleccione una variable</h4>
                    <Button style={{ position: "absolute", top: "10px", right: "10px" }} variant="contained" color="primary" onClick={handleCloseModall}>
                        X
                    </Button>
                    <Select1
                        message="Dispositivos"
                        value={DispositivoE}
                        setFunction={ConsumirDispositivo}
                        data={Dispositivos}
                        id="outlined-disabled"
                    />

                    <TablaDispo> 
                        <Tr>
                            <Th>Dispositivo</Th>
                            <Th>Ultimo dato</Th>
                        </Tr>

                        {datoTabla.length > 0 ? (
                            datoTabla.map((dato, index) => (
                                <>
                                    {dato.ultimoReporte != "Nunca a Reportado" ? <TrC>
                                        <td>
                                            <ContenedorDis>
                                                <Nombredispositivo key={index} onClick={() => VerificarId(dato.deviceID)}> {dato.name}</Nombredispositivo>
                                                <DescripcionDispo>{dato.deviceID}</DescripcionDispo>
                                            </ContenedorDis></td>
                                        <td>
                                            <Organizacion>{dato.ultimoReporte}</Organizacion>
                                        </td>
                                    </TrC> : null}
                                    {showVariables &&
                                        selectedDevice &&
                                        selectedDevice === dato.deviceID && (
                                            <>
                                                <div>
                                                    {dato.variables.map((variable, index) => (
                                                        <p
                                                            key={index}
                                                            onClick={() =>
                                                                handleVariableClick(variable + "-" + dato.deviceID + "-" + dato.name)
                                                            }
                                                        ><ArrowRightIcon />
                                                            {variable}
                                                        </p>
                                                    ))}
                                                </div>
                                            </>
                                        )}

                                </>
                            ))
                        ) : (
                            <p>No hay datos</p>
                        )}
                    </TablaDispo>
                </ModalCentro>                

            </Main>
        </>
    )
}

export default Line