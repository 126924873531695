import React, { useRef, useEffect } from 'react';
import GaugeComponent from 'react-gauge-component'

function Presion( {value, unidad} ) {
    
    return (
        <>
            <GaugeComponent
                arc={{
                    subArcs: [
                        {
                            limit: 20,
                            color: '#EA4228',
                            showTick: true
                        },
                        {
                            limit: 40,
                            color: '#F58B19',
                            showTick: true
                        },
                        {
                            limit: 60,
                            color: '#F5CD19',
                            showTick: true
                        },
                        {
                            limit: 100,
                            color: '#5BE12C',
                            showTick: true
                        },
                        {
                            color: '#951414'
                        }
                    ]
                }}
                labels={{
                    valueLabel: {
                        formatTextValue: value => value + ` ${unidad}`,
                        style: {
                            fill: "#000000",
                            textShadow: "none"
                        }
                    },
                    tickLabels: {
                        defaultTickValueConfig: {
                            formatTextValue: value => value + ` ${unidad}`,
                            style: {
                                fill: "#000000",
                                textShadow: "none"
                            }
                        }
                    }
                }}
                value={value}
            />
        </>
    )
}

export default Presion;
