import React, { useState, useEffect } from "react";
import Swal from 'sweetalert2'
import Input from "../../../components/Entradas/Input";
import InputPassword from "../../../components/Entradas/InputPassword/index";
import { useHistory } from "react-router-dom";
import API from "../../../services/api-service";
import Select from "../../../components/Entradas/Select";
import { useLocation } from "react-router-dom";
import ButtonS from "../../../components/Button";
import Auth from "../../../services/auth-service";
import {
  ContenedorTitulo,
  Main,
  Titulo,
  BotonDevice,
  ContenedorButton,
} from "../UsuariosElements";

const Roles = [
  {
    value: "administrador",
    label: "Administrador",
  },
  {
    value: "usuario",
    label: "Usuario",
  },
  {
    value: "visualizador",
    label: "Visualizador",
  },
];
const Estados = [
  {
    value: "habilitado",
    label: "Habilitado",
  },
  {
    value: "inhabilitado",
    label: "Inhabilitado",
  },
];

const CreateUser = () => {

    const [postUsuario, setUsuario] = useState(null);
    const [postNombre, setNombre] = useState(null);
    const [postPassword, setPassword] = useState(null);
    const [postEmail, setEmail] = useState(null);
    const [postCons, setContra] = useState(null);
    const [postRol, setRol] = useState("usuario");
    const [postEstado, setEstado] = useState("habilitado");
    const [postOrganizaciones, setOrganizaciones] = useState([]);
    const [postHabilitaciones, setHabilitaciones] = useState([]);

    var history = useHistory();
    
    const query = new URLSearchParams(useLocation().search);
    const obteOrganizacion = query.get("organizacion");

    useEffect(()=>{
        setOrganizaciones(Auth.getValidacionU())
    },[])

    function sendData() {
      if(postCons!=postPassword){
        Swal.fire("Las contraseñas deben ser las mismas", '', 'error')
      }else{
        API.crearUsuario(postUsuario,postPassword,postEmail,postOrganizaciones,postRol,postNombre,postEstado).then((respues)=>{
            if(respues.status == true){
                Swal.fire(`${respues.error}`, '', 'error')
            }else{
                Swal.fire(`${respues.message}`, '', 'success')
                var url = `/admin/userCount?organizacion=${obteOrganizacion}`
                history.push(url);
            }
        },(error)=>{

        })
        
      }
    }

  return (
    <>
      <Main>
        <ContenedorTitulo>
          <Titulo>Crear nuevo Usuario</Titulo>
        </ContenedorTitulo>
        <Input
          message="Ingrese el Usuario"
          value={postUsuario}
          setFunction={setUsuario}
          id="outlined-disabled"
        />
        <Input
          message="Ingrese el Nombre"
          value={postNombre}
          setFunction={setNombre}
          id="outlined-disabled"
        />
        <InputPassword
        type="password"
          message="Ingrese la Contraseña"
          value={postPassword}
          setFunction={setPassword}
          id="outlined-disabled"
        />
        <InputPassword
        type="password"
          message="Confirmar Contraseña"
          value={postCons}
          setFunction={setContra}
          id="outlined-disabled"
        />
        <Input
          message="Ingrese el Email"
          value={postEmail}
          setFunction={setEmail}
          id="outlined-disabled"
        />

        <Select
          message="Rol"
          value={postRol}
          setFunction={setRol}
          data={Roles}
          id="outlined-disabled"
        />
        <Select
          message="Estado"
          value={postEstado}
          setFunction={setEstado}
          data={Estados}
          id="outlined-disabled"
        />



        <ContenedorButton>
          <ButtonS
            url={`/admin/userCount?organizacion=${obteOrganizacion}`}
            message="Regresar"
            style={BotonDevice}
          >
            Regresar
          </ButtonS>
          <BotonDevice
            onClick={() => {
              sendData();
            }}
          >
            Guardar
          </BotonDevice>
        </ContenedorButton>
      </Main>
    </>
  );
};

export default CreateUser;
