import React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

const Select = (props) => {
  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "15rem" },
        }}
        noValidate
        autoComplete="off"
      >
        {props.submessage ? (
          <TextField
            id="outlined-select-currency"
            select
            label={props.message}
            value={props.value}
            onChange={(e) => props.setFunction(e.target.value)}
            helperText={props.submessage}
          >
            {props.data.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        ) : (
          <TextField
            id="outlined-select-currency"
            select
            label={props.message}
            value={props.value}
            onChange={(e) => props.setFunction(e.target.value)}

            // helperText="Por favor escoja la zona horaria del dispositivo"
          >
            {props.data.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
    </>
  );
};

export default Select;
