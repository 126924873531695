import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import API from "../../services/api-service"

export default function PdfReport({ data, nombre }) {
    const [isLoading, setIsLoading] = useState(false);

    const handleGeneratePDF = async () => {
        setIsLoading(true);
        const info = { data, nombre };
        try {
            const response = await API.generarReportePDF(info);

            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'reporte.pdf');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            } else {
                alert("Problemas al generar el PDF, revise su conexión e intente nuevamente");
            }
        } catch (error) {
            alert("Problemas al generar el PDF, revise su conexión e intente nuevamente");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Button className="buttonGeneral" onClick={handleGeneratePDF} disabled={isLoading}>
                {isLoading ? (
                    <>
                        <Spinner animation="border" size="sm" role="status" aria-hidden="true" />
                        Cargando...
                    </>
                ) : (
                    "Exportar PDF"
                )}
            </Button>
        </>
    );
}
