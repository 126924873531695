import react from "react"

const Index = () => {
    return (
        <>
            Hola mundo de mapa
        </>
    )
}

export default Index