import React from "react";
import { useHistory } from "react-router-dom";

const Button = (props) => {

  let history = useHistory();
  return (
    <props.style
      onClick={() => {
        history.push(props.url);
      }}
    >
      {props.message}
    </props.style>
  );
};

export default Button;
