import React, { useEffect, useRef, useState } from "react";
import "./humedad.css"
import EditIcon from '@material-ui/icons/Edit';
import {
    IconoEdit
} from './ElementsDisplay';

//VALIDAR ENTRADA MAX_LENGTH DE TEXTO PARA NO OCASIONAR DESBORDE

const App = (props) => {
    const containerRef = useRef(null);
    const [fontSize, setFontSize] = useState("2rem");

    function handleMouseOver(event) {
        event.target.style.cursor = 'pointer';
    }

    const index = props?.index
    const info = props?.info




    useEffect(() => {
        const resizeText = () => {
            const container = containerRef.current;
            if (container) {
                const containerWidth = container.offsetWidth;
                const containerHeight = container.offsetHeight - 20;
                // Ajusta el tamaño del texto en función del ancho y alto del contenedor
                const widthFontSize = (containerWidth / props.data.length) + 10;
                const heightFontSize = containerHeight;
                const newFontSize = `${Math.min(widthFontSize, heightFontSize)}px`;
                setFontSize(newFontSize);
            }
        };

        // Llama a resizeText cuando cambie el tamaño del contenedor
        const container = containerRef.current;
        if (container) {
            const observer = new ResizeObserver(resizeText);
            observer.observe(container);
        }

        // Llama a resizeText una vez al cargar la página para establecer el tamaño inicial
        resizeText();

        return () => {
            // Detiene la observación cuando el componente se desmonta
            const container = containerRef.current;
            if (container) {
                const observer = new ResizeObserver(resizeText);
                observer.unobserve(container);
            }
        };
    }, [props.data]); // Asegurarse de que se ajuste al cambiar el texto


    return (
        <div
            ref={containerRef}
            style={{
                backgroundColor: props.backgroundColor,
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",              

            }}
            >
            <IconoEdit style={{ top: 0, right: 0, position: "absolute" }}>
                <EditIcon style={{}} onClick={() => props.funcionE({ index: index, widget: info })} onMouseOver={handleMouseOver} />
            </IconoEdit>
            <div
                ref={containerRef}
                style={{
                    height: "100%",
                    width: "100%",
                    //backgroundColor: props.backgroundColor,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",

                }}
            >
                <p
                    style={{
                        fontSize: fontSize,
                        maxWidth: "100%",
                    }}
                    className="widgets-font"
                >
                    {props.data}
                </p>
            </div>

        </div>
    );
};

export default App;
