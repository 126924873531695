import PlaceHolder from "../../components/Dashboard/Components/PlaceHolder";
import toast, { Toaster} from "react-hot-toast";
import Modal from "../../components/Modal/Modal left-right/index";
import ModalR from "../../components/Modal/Modal right-left/index";
import { HTML5Backend } from "react-dnd-html5-backend";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import API from "../../services/api-service";
import TextField from "@mui/material/TextField";
import { DndProvider } from "react-dnd";
import Probando from "./probando";
import Tablaa from "./tabla";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import "./bg.css";
import MenuIcon from '@material-ui/icons/Menu';



import {  
  Main,  
  Example,
  CTituloModal,
  TituloModal,
  ContenedorBotones,
  ContenedorBotoness,  
  MenuHamb,
  IcoHam,
  TituloHam,
  ContenedorAdd,
  TituloAdd,
  WidgetAdd,  
} from "./DashboardElements";


const Dashboard = () => {
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const organizacion = query.get("organizacion");
  const localizacion = query.get("localizacion");
  const [data, setData] = useState([]);
  const [validad, setValidad] = useState(false);
  const [showModalDash, setShowModalDash] = useState(false);
  const [showModalCrearDash, setShowModalCrearDash] = useState(false);
  const [showModalWidget, setShowModalWidget] = useState(false); 
  const [dash, setDash] = useState(null);  
  const [modalEdit, setModalEdit] = useState(false);
  const [letrasC, setLetrasC] = useState(false)
  const [nombreDash, setNombreDash] = useState("")


  const columnas = [
    {
      label: "Nombre",
    },
    {
      label: "",
    }

  ]

  const consumirAllDas = () => {
    let data = {
      organizacion: organizacion,
      localizacion: localizacion,
    };
    API.obtenerTodoD(data).then(
      (respuesta) => {
        setDash(respuesta);
        handleShowModalDash();
      },
      (error) => { }
    );
  };

  const actualizarD = (valor) => {

    let data = {
      organizacion: organizacion,
      localizacion: localizacion,
      newDashboard: valor,
    };
    API.actualizarDashboard(data).then(
      (respuesta) => {
        setShowModalDash(false);
        consumir();
      },
      (error) => { }
    );
  };

  const handleShowModalDash = () => {
    setShowModalDash(true);
  };

  const handleCloseModalDash = () => {
    setShowModalDash(false);
  };

  const handleShowModalCrearDash = () => {
    setShowModalCrearDash(true);
  };

  const handleCloseModalCrearDash = () => {
    setShowModalCrearDash(false);
  };
  const handleShowModalWidget = () => {
    setShowModalWidget(true);
  };

  const handleCloseModalWidget = () => {
    setShowModalWidget(false);
  };

  function handleMouseOver(event) {
    event.target.style.cursor = 'pointer';
  }

  const actualizarnew = (data) => {
    handleCloseModalWidget();
    toast.promise(
      API.agregarNuevoWidget(data).then((response) => {
        setData(response);
        // if (Object.keys(response).length > 0) {
        //   setValidad(true);
        //   setData(response);
        // }
      }),
      {
        loading: "Cargando...",
        success: <b>Widget Creado.!</b>,
        error: (err) => <b>Error al cargar datos!</b>,
      }
    );
  };

  const eliminarWidget = (data) => {
    // handleCloseModalll();
    toast.promise(
      API.eliminarWidget(data).then((response) => {
        setModalEdit(false)
        // setData(response);
        consumirWidgetEliminado()
      }),
      {
        loading: "Cargando...",
        success: <b>Widget Eliminado.!</b>,
        error: (err) => <b>Error al cargar datos!</b>,
      }
    );
  };

  const crearDashboard = () => {
    API.newDashboard(organizacion, localizacion, nombreDash).then(
      (respuesta) => {
        handleCloseModalCrearDash()
        consumirAllDas()
      },
      (error) => { }
    );
  }  

  function consumirWidgetEliminado() {
    // setData([]);
    let data = {
      organizacion: organizacion,
      localizacion: localizacion,
    };

    API.getAllDas(data).then((response) => {
      if (response.error == "error") {
        setLetrasC(true)
      } else {
        setLetrasC(false)
      }
      if (Object.keys(response).length > 0) {
        setValidad(true);
        setData(response);
      }
    })
  }
  
  function consumir() {
    // setData([]);
    let data = {
      organizacion: organizacion,
      localizacion: localizacion,
    };
    toast.promise(
      API.getAllDas(data).then((response) => {
        if (response.error == "error") {
          setLetrasC(true)
        } else {
          setLetrasC(false)
        }
        if (Object.keys(response).length > 0) {
          setValidad(true);
          setData(response);
        }

      }),
      {
        loading: "Cargando...",
        success: <b>Datos cargados!</b>,
        error: (err) => <b>Error al cargar datos!</b>,
      }
    );
  }

  useEffect(() => {
    consumir();
  }, [location.search]);

  return (
    <>
      {/* Modal para ver dashbpoard*/}
      <Modal isOpen={showModalDash} onClose={handleCloseModalDash}>
        <Example>
          <CTituloModal>
            <TituloModal>Dashboards</TituloModal>

          </CTituloModal>


          {dash != null
            ?
            <div className="dashboard-modal-table">
              <Tablaa
                columnas={columnas}
                datos={dash}
                actualizarD={actualizarD}
                recargar={consumirAllDas}
              />
            </div>
            : null}

          <ContenedorBotones>
            <ContenedorBotoness>
              <Stack spacing={2} direction="row">
                <Button variant="contained" onClick={handleShowModalCrearDash}>
                  Crear
                </Button>
                <Button variant="outlined" onClick={handleCloseModalDash}>
                  Cerrar
                </Button>
              </Stack>
            </ContenedorBotoness>
          </ContenedorBotones>
        </Example>
      </Modal>
      {/* FIn del primer modal */}
      <ModalR isOpen={showModalCrearDash} onClose={handleCloseModalCrearDash}>

        <Example>
          <CTituloModal style={{
              marginBottom: "40px"
            }}>
            <TituloModal>Crear Dashboard</TituloModal>

          </CTituloModal>

          <TextField
            id="outlined-disabled"
            label="Nombre del Dashboard"
            type="text"
            value={nombreDash}
            InputLabelProps={{
              shrink: true,
            }}
            
            onChange={(e) => setNombreDash(e.target.value)}
          />


          <ContenedorBotones>
            <ContenedorBotoness>
              <Stack spacing={2} direction="row">
                <Button variant="contained" onClick={crearDashboard}>
                  Guardar
                </Button>
                <Button variant="outlined" onClick={handleCloseModalCrearDash}>
                  Cancelar
                </Button>
              </Stack>
            </ContenedorBotoness>
          </ContenedorBotones>
        </Example>



      </ModalR>

      <ModalR isOpen={showModalWidget} onClose={handleCloseModalWidget}>
        <Example>
          <CTituloModal>
            <TituloModal>Widgets</TituloModal>

          </CTituloModal>
          <Probando funcionO={actualizarnew} data={data} cerrarModal={handleCloseModalWidget} />

          <ContenedorBotones>
            <ContenedorBotoness>
              <Stack spacing={1} direction="row">
                <Button variant="outlined" onClick={handleCloseModalWidget}>
                  Cerrar
                </Button>
              </Stack>
            </ContenedorBotoness>
          </ContenedorBotones>
        </Example>

        {/* <i onClick={ProbandoIcono}>
          <AccessAlarmIcon />
        </i>
        <BotonM onClick={handleshowModalWidget}>Crear</BotonM>
        <br />
        <button onClick={handleCloseModalll}>Cerrar</button> */}
      </ModalR>
      {/* Este es para al momento de agregar un widget */}
      
      
      
      <Main>
        <MenuHamb >
          <IcoHam onMouseOver={handleMouseOver} onClick={consumirAllDas}><MenuIcon style={{ fontSize: '40px' }} /></IcoHam>
          {letrasC ? <TituloHam onMouseOver={handleMouseOver} onClick={consumirAllDas}>Crea un nuevo Dashboard</TituloHam> : <TituloHam onMouseOver={handleMouseOver} onClick={consumirAllDas}>{data.nombreDasborah}</TituloHam>}
        </MenuHamb>

        {letrasC ? null : <ContenedorAdd>
          <TituloAdd>Añadir Widget</TituloAdd>
          <WidgetAdd><AddCircleIcon onClick={handleShowModalWidget} onMouseOver={handleMouseOver} style={{ color: '#194a94', fontSize: '50px', textAlign: 'right' }} /></WidgetAdd>
        </ContenedorAdd>}


        {/* <div onMouseOver={handleMouseOver} onClick={consumirAllDas}><MenuIcon/>{data.nombreDasborah}</div> */}
        {/* {showModal ? <p>Es </p> : <p>No Es </p>}
        <button onClick={handleCloseModal}>Cerrar</button>
        <button onClick={consumirAllDas}>Mostrar Modal</button>{letrasC ? <p>Crear un dashboard</p> : null}
        <button onClick={handleShowModall}>Mostrar Modal2</button>
        <button onClick={handleShowModalll}>Agregar Widgets</button> */}

        {organizacion == "uArtes"? (
          <div>
            Enlace cámaras <a href="https://200.1.112.227/" target="_blank">https://200.1.112.227/</a> el enlace esta disponible únicamente en FireFox, copialo y pégalo
          </div>
        ) : null}

        {validad == true ? (
          <>
            <DndProvider backend={HTML5Backend}>

              <PlaceHolder Data={data} funcionEliminar={eliminarWidget} ModalEdit={setModalEdit} estadoM={modalEdit} consumir={consumir}>

              </PlaceHolder>

            </DndProvider>
          </>
        ) : null}
        <Toaster position="top-center" reverseOrder={false} />
      </Main>
    </>
  );
};

export default Dashboard;
