import React, { useState, useEffect } from "react";
import {
  ContenedorTitulo,
  Main,
  Titulo,
  ContenedorFecha
} from "../TestMapa/AlcantarillaElements";
import { Button } from "react-bootstrap";
import ModalCentro from "../../components/Modal/Modal Centro";
import Tabla from "../Historico/Tabla/index"
import TextField from "@mui/material/TextField";
import MultiSelect from 'react-multiple-select-dropdown-lite';
import "react-multiple-select-dropdown-lite/dist/index.css";
import toast from "react-hot-toast";
import AUTH from "../../services/auth-service"
import Api from "../../services/api-service"
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const Index = () => {
  const [showModal, setShowModal] = useState(false);
  const [dispositivoSeleccionado, setDispositivoSeleccionado] = useState()
  const [tipoDe, setTipoDe] = useState()
  const [nombreNuevo, setNombreNuevo] = useState()
  const [posteNuevo, setPosteNuevo] = useState()
  const itemsPerPageOptions = [5, 10, 20, 50, 100, 200];
  const [nombreBusqueda, setNombreBusqueda] = useState("")
  const [IDAtcualizar, setIDActualizar] = useState("")
  const [tipoDevice, setTipoDevice] = useState("")
  const [controlador, setControlador] = useState(false)
  const [id, setID] = useState("")
  const [direccion, setDireccion] = useState("")
  const [numPoste, setNumPoste] = useState()
  const [rango, setRango] = useState()
  const [respuesta, setRespuesta] = useState([])
  const [dataFiltrada, setDataFiltrada] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const location = useLocation();
  const query = new URLSearchParams(useLocation().search);
  const obteOrganizacion = query.get("organizacion");
  const localizacion = query.get("localizacion");
  const [isOpen, setIsOpen] = useState(false);
  const [usuario, setUsuario] = useState("")
  const history = useHistory();

  useEffect(async () => {
    try {
      const auth = await AUTH.getOrganizaciones()
      const user = await AUTH.getUser()
      setUsuario(user)
      let datas = ""

      auth.map((index) => {
        index.localizacion.map((locali) => {
          if (locali.localiza === localizacion) {
            locali.configuraciones.map((conf) => {
              if (conf.tipo === "mapa") {
                datas = conf.catalogo
              }
            })
          }
        })
      })
      
      setTipoDe(datas)
      Api.obtenerDispositivosSirenas(obteOrganizacion, localizacion, datas).then(
        (respuesta) => {
          if (respuesta.length > 0) {
            filtrarData(respuesta);
          }
        },
        (error) => { }
      );

    } catch (error) {
      console.log(error);
    }
  }, [location.search, nombreBusqueda, id, direccion, numPoste, rango, controlador]);

  useEffect(() => {
    setSelectedFilters([])
    setNombreBusqueda("")
    setID("")
    setDireccion("")
    setRango("")
    setNumPoste("")
  }, [location.search])

  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '3rem',
    marginBottom: '10px', // Espacio entre los botones y otros elementos, ajusta según necesites
  };

  const buttonStyle = {
    display: 'flex',
    justifyContent: 'center',
  };

  const verStatus = (e) => {
    history.push(
      `/admin/viewStatus?organizacion=${obteOrganizacion}&localizacion=${localizacion}`
    );
  };

  const verActivaciones = (e) => {
    history.push(
      `/admin/viewReports?organizacion=${obteOrganizacion}&localizacion=${localizacion}`
    );
  };

  const verDesconexiones = (e) => {
    history.push(
      `/admin/viewDesconexiones?organizacion=${obteOrganizacion}&localizacion=${localizacion}`
    );
  };

  const handleOnchange = val => {
    setSelectedFilters(val);

    if (!val.includes('Nombre')) {
      setNombreBusqueda('');
    }
    if (!val.includes('id')) {
      setID('');
    }
    if (!val.includes('direccion')) {
      setDireccion('');
    }
    if (!val.includes('numPoste')) {
      setNumPoste('');
    }
    if (!val.includes('rango')) {
      setRango('');
    }
  };

  const filtrarData = (data) => {
    let filtered = data.filter(item => {
      let matchNombreDispo = true;
      let matchID = true;
      let matchDireccion = true;
      let matchPoste = true;
      let matchRango = true;

      if (localizacion === "sto_epconst_loca_1") {
        if (selectedFilters.includes('Nombre') && nombreBusqueda !== "") {
          matchNombreDispo = item.nombre.toLowerCase().includes(nombreBusqueda.toLowerCase());
        }

        if (selectedFilters.includes('id') && id !== "") {
          matchID = item.id.toLowerCase().includes(id.toLowerCase());
        }

        if (selectedFilters.includes('direccion') && direccion !== "") {
          if (typeof item.ubicacionCalles === 'string') {
            matchDireccion = item.ubicacionCalles.toLowerCase().includes(direccion.toLowerCase());
          } else if (typeof item.ubicacionCalles === 'number') {
            matchDireccion = item.ubicacionCalles.toString().includes(direccion);
          }
        }
      } else {
        if (selectedFilters.includes('Nombre') && nombreBusqueda !== "") {
          matchNombreDispo = item.nombre.toLowerCase().includes(nombreBusqueda.toLowerCase());
        }

        if (selectedFilters.includes('id') && id !== "") {
          matchID = item.idSirena.toLowerCase().includes(id.toLowerCase());
        }

        if (selectedFilters.includes('direccion') && direccion !== "") {
          matchDireccion = item.ubicacionCalles.toLowerCase().includes(direccion.toLowerCase());
        }

        if (selectedFilters.includes('numPoste') && numPoste !== "") {
          if (typeof item.codigoPoste === 'string') {
            matchPoste = item.codigoPoste.toLowerCase().includes(numPoste.toLowerCase());
          } else if (typeof item.codigoPoste === 'number') {
            matchPoste = item.codigoPoste.toString().includes(numPoste);
          }
        }

        if (selectedFilters.includes('rango') && rango !== "") {
          matchRango = item.rango.toLowerCase().includes(rango.toLowerCase());
        }
      }

      return matchID && matchNombreDispo && matchDireccion && matchPoste && matchRango;
    });
    setDataFiltrada(filtered);
  };

  let columnValues

  let options
  switch (localizacion) {
    case "sto_epconst_loca_1":
      columnValues = [
        { nColumna: 'Nombre', nValor: 'nombre' },
        { nColumna: 'ID', nValor: 'id' },
        { nColumna: 'Fecha creación', nValor: 'fechaCreacion' },
        { nColumna: 'Dirección', nValor: 'ubicacionCalles' },
        { nColumna: 'Acciones', nValor: 'acciones' },
      ];
      options = [
        { label: 'Nombre', value: 'Nombre' },
        { label: 'ID', value: 'id' },
        { label: 'Dirección', value: 'direccion' },
      ];
      break;
    case "sto_epconst_loca_2":
      columnValues = [
        { nColumna: 'Nombre', nValor: 'nombre' },
        { nColumna: 'ID', nValor: 'idSirena' },
        { nColumna: 'Fecha creación', nValor: 'fechaCreacion' },
        { nColumna: 'Dirección', nValor: 'ubicacionCalles' },
        { nColumna: '# Poste', nValor: 'codigoPoste' },
        { nColumna: 'Rango', nValor: 'rango' },
        { nColumna: 'Acciones', nValor: 'acciones' },
      ];
      options = [
        { label: 'Nombre', value: 'Nombre' },
        { label: 'ID', value: 'id' },
        { label: 'Dirección', value: 'direccion' },
        { label: '# Poste', value: 'numPoste' },
        { label: 'Rango', value: 'rango' }
      ];
      break;
    case "iba_gad_loca_1":
      columnValues = [
        { nColumna: 'Nombre', nValor: 'nombre' },
        { nColumna: 'ID', nValor: 'id' },
        { nColumna: 'Fecha creación', nValor: 'fechaCreacion' },
        { nColumna: 'Dirección', nValor: 'ubicacionCalles' },
        { nColumna: 'Acciones', nValor: 'acciones' },
      ];
      options = [
        { label: 'Nombre', value: 'Nombre' },
        { label: 'ID', value: 'id' },
        { label: 'Dirección', value: 'direccion' },
      ];
      break;
  }




  const setEditarNombre = (dispositivo) => {
    setNombreNuevo(dispositivo.nombre);
    setDispositivoSeleccionado(dispositivo);
    setTipoDevice(dispositivo.catalogo)
    if (dispositivo.catalogo === "sirenas_01") {
      setPosteNuevo(dispositivo.codigoPoste);
      setIDActualizar(dispositivo.idSirena)
    } else {
      setIDActualizar(dispositivo.id)
    }
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const handleOnChange = (selected) => {
    setSelectedFilters(selected);
    setIsOpen(false); // Cierra el menú desplegable después de la selección
  };

  const verificarNuevoNombre = (e) => {
    const inputValue = e.target.value;
    setNombreNuevo(inputValue);
  }

  const verificarPoste = (e) => {
    const inputValue = e.target.value;
    setPosteNuevo(inputValue)
  }

  const actualizarNombre = () => {
    let documento = {
      organizacion: obteOrganizacion,
      localizacion: localizacion,
      deviceID: IDAtcualizar,
      tipoDevice: tipoDevice,
      dataActualizar: {
        nombre: nombreNuevo
      }
    }

    if (tipoDevice === "sirenas_01") {
      documento.dataActualizar.codigoPoste = posteNuevo;
    }

    Api.actualizarNombreSirenas(documento).then((respuesta) => {
      if (respuesta.message == "Se actualizó todo OK") {
        Swal.fire("Dispositivo actualizado correctamente", "", "success");
        setShowModal(false)
        setControlador(!controlador)
      }
    })

  }


  return (
    <>
      {dispositivoSeleccionado != null && (
        <ModalCentro isOpen={showModal} onClose={closeModal}>
          <h4>Actualizar Datos</h4>
          <Button style={{ position: "absolute", top: "10px", right: "10px" }} onClick={closeModal}>
            X
          </Button>
          <ContenedorFecha>
            <TextField
              label="Nombre"
              value={nombreNuevo}
              onChange={verificarNuevoNombre}
            />
          </ContenedorFecha>
          {localizacion === "sto_epconst_loca_2" && (
            <>
              <ContenedorFecha>
                <TextField
                  label="# Poste"
                  value={posteNuevo}
                  onChange={verificarPoste}
                />
              </ContenedorFecha>
              {/* <ContenedorFecha>
                <TextField
                  label="Latitud"
                  value={posteNuevo}
                  onChange={verificarPoste}
                />
              </ContenedorFecha>
              <ContenedorFecha>
                <TextField
                  label="Longitud"
                  value={posteNuevo}
                  onChange={verificarPoste}
                />
              </ContenedorFecha> */}
            </>

          )}
          <ContenedorFecha>
            <Button className="buttonGeneral" onClick={() => actualizarNombre()}>
              Actualizar
            </Button>
          </ContenedorFecha>
        </ModalCentro>
      )}
      <Main>
        <ContenedorTitulo>
          <Titulo>Lista de dispositivos</Titulo>
        </ContenedorTitulo>
        {/* <div style={buttonStyle}>
        <Button className="buttonGeneral" onClick={verStatus}>
          Verificar Estado
        </Button>
      </div> */}
        {localizacion == "sto_epconst_loca_2" ?
          <div style={containerStyle}>
            <div style={buttonStyle} onClick={verActivaciones}>
              <Button className="buttonGeneral">
                Ver Actividad
              </Button>
            </div>
            {usuario === "Victor" || usuario === "victor1" ?
              <div style={buttonStyle} onClick={verDesconexiones}>
                <Button className="buttonGeneral">
                  Ver Desconexiones
                </Button>
              </div>
              : null}
          </div>
          : null}

        <div style={{ display: "flex", flexDirection: 'column',height: "100%" }}>
          <ContenedorFecha>
            <MultiSelect
              onChange={handleOnchange}
              placeholder={"Filtros"}
              options={options}
              defaultValue={selectedFilters}
              closeOnSelect={true}
            />
          </ContenedorFecha>

          {selectedFilters.includes('Nombre') && (
            <ContenedorFecha>
              <TextField
                label="Nombre Dispositivo"
                value={nombreBusqueda}
                onChange={(e) => setNombreBusqueda(e.target.value)}
              />
            </ContenedorFecha>
          )}

          {selectedFilters.includes('id') && (
            <ContenedorFecha>
              <TextField
                label="ID"
                value={id}
                onChange={(e) => setID(e.target.value)}
              />
            </ContenedorFecha>
          )}
          {selectedFilters.includes('direccion') && (
            <ContenedorFecha>
              <TextField
                label="Dirección"
                value={direccion}
                onChange={(e) => setDireccion(e.target.value)}
              />
            </ContenedorFecha>
          )}
          {selectedFilters.includes('numPoste') && (
            <ContenedorFecha>
              <TextField
                label="# Poste"
                value={numPoste}
                onChange={(e) => setNumPoste(e.target.value)}
              />
            </ContenedorFecha>
          )}
          {selectedFilters.includes('rango') && (
            <ContenedorFecha>
              <TextField
                label="Rango"
                value={rango}
                onChange={(e) => setRango(e.target.value)}
              />
            </ContenedorFecha>
          )}
        </div>
        </Main >
        <Main>
          {dataFiltrada.length > 0 ? <Tabla data={dataFiltrada} itemsPerPageOptions={itemsPerPageOptions} columnValues={columnValues} setShowEditNomb={setEditarNombre} tipoDispositivo={tipoDe} /> : null}
        </Main>
        
      
    </>
  )
}

export default Index