import React, { useRef, useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { DeviceUnknown, Bluetooth, Wifi, OfflineBolt } from "@material-ui/icons";
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';
import { useHistory } from "react-router-dom";
import MovimiI from "./mov.png"
import "./styles.css";

const ImageWithPoints = ({ imageUrl, data }) => {

  const imageRef = useRef(null);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [title, setTitle] = useState("");
  const [blink, setBlink] = useState(false);
  const [logoColor, setLogoColor] = useState("black");
  const query = new URLSearchParams(useLocation().search);
  const organizacionn = query.get("organizacion");
  const localizacionn = query.get("localizacion");
  let history = useHistory();



  useEffect(() => {
    if (imageRef.current) {
      const image = new Image();
      image.src = imageUrl;
      image.onload = () => {
        setImageWidth(image.width);
        setImageHeight(image.height);
      };
    }
  }, [imageUrl]);

  useEffect(() => {
    const interval = setInterval(() => {
      const index = Math.floor(Math.random() * data.length);
      const { nombre } = data[index];
      setTitle(nombre);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [data]);

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      setBlink((prevState) => !prevState);
    }, 1000);

    return () => {
      clearInterval(blinkInterval);
    };
  }, []);

  useEffect(() => {
    if (blink) {
      setLogoColor("black");
    } else {
      setLogoColor("white");
    }
  }, [blink]);



  const calculateScaledCoordinates = (posX, posY) => {
    const scaledX = ((posX + imageWidth) / imageWidth) * 100;
    const scaledY = ((posY + imageHeight) / imageHeight) * 100;
    return { x: scaledX, y: scaledY };
  };

  const getDeviceIcon = (catalogo) => {
    switch (catalogo) {
      case "bleSensor_1":
        return <Bluetooth />;
      case "bleSensor_2":
        return <Wifi />;
      case "s1_favorita_1":
      case "mk_107":
        return <DeviceThermostatIcon />;
      case "mk_117":
        return <OfflineBolt />;
      default:
        return <DeviceUnknown />;
    }
  };

  const handlePointMouseLeave = () => {
    setSelectedDevice(null);
  };

  const NextPage = (device) => {
    const partes = device.split("-");
    const antes = partes[0];
    const despues = partes[1];
    let nombreD = partes[2];
    if (nombreD == null || nombreD == undefined || nombreD == "") {
      nombreD = antes;
    }
    let url = `/admin/historico?organizacion=${organizacionn}&localizacion=${localizacionn}&deviceID=${antes}&tipo=${despues}&nombre=${nombreD}`;
    history.push(url);
  };

  const getPointBackgroundColor = (temperature) => {
    const punto = data[0].rango.primerNivel.valor1;
    const punto1 = data[0].rango.primerNivel.valor2;
    const punto2 = data[0].rango.primerNivel.valor2;
    const punto3 = data[0].rango.segundoNivel.valor2;
    const punto4 = data[0].rango.segundoNivel.valor2;
    const punto5 = data[0].rango.tercerNivel.valor2;

    if (temperature != false) {
      // Verifica si temperature tiene la propiedad temperature o Tamiente
      const tempValue = temperature.temperature !== undefined ? temperature.temperature : temperature.Tambiente;

      if (tempValue !== undefined) {
        if (blink) {
          return ["#FFFFFF", "#FFFFFF"];
        } else {
          if (tempValue >= punto && tempValue < punto1) {
            const percent = (tempValue - punto) / (punto1 - punto);
            const color2 = interpolateColor("#00FFFF", "#0000FF", percent);
            const color1 = interpolateColor("#0000FF", "#00FFFF", percent);
            return [color1, color2];
          } else if (tempValue >= punto2 && tempValue < punto3) {
            const percent = (tempValue - punto2) / (punto3 - punto2);
            const color1 = interpolateColor("#FFFF00", "#00FF00", percent);
            const color2 = interpolateColor("#00FF00", "#FFFF00", percent);
            return [color1, color2];
          } else if (tempValue >= punto4 && tempValue <= punto5) {
            const percent = (tempValue - punto4) / (punto5 - punto4);
            const color1 = interpolateColor("#FFA500", "#FF0000", percent);
            const color2 = interpolateColor("#FF0000", "#FFA500", percent);
            return [color1, color2];
          } else if (tempValue < punto || tempValue > punto5) {
            const percent = 1;
            const color1 = interpolateColor("800080", '#800080', percent);
            const color2 = interpolateColor("800080", '#800080', percent);
            return [color1, color2];
          }
        }
      } else {
        return ["#FF0000", "#FFA500"];
      }
    } else {
      return ["#FF0000", "#FFA500"];
    }
  };


  const interpolateColor = (color1, color2, percent) => {
    const hexToRgb = (hex) => {
      const bigint = parseInt(hex.slice(1), 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return { r, g, b };
    };

    const rgbToHex = (rgb) => {
      return (
        "#" +
        ((1 << 24) | (rgb.r << 16) | (rgb.g << 8) | rgb.b)
          .toString(16)
          .slice(1)
      );
    };

    const color1Rgb = hexToRgb(color1);
    const color2Rgb = hexToRgb(color2);

    const r = Math.round(
      color1Rgb.r + (color2Rgb.r - color1Rgb.r) * percent
    );
    const g = Math.round(
      color1Rgb.g + (color2Rgb.g - color1Rgb.g) * percent
    );
    const b = Math.round(
      color1Rgb.b + (color2Rgb.b - color1Rgb.b) * percent
    );

    return rgbToHex({ r, g, b });
  };

  return (
    <div className="image-container">
      <img ref={imageRef} src={imageUrl} alt="Croquis" />
      {imageWidth > 0 && imageHeight > 0 && (
        <>
          {data.map((item, index) => {
            const { posx, posy } = item.posicionSVG;
            const { x, y } = calculateScaledCoordinates(posx, posy);
            const { deviceID, catalogo, nombre } = item;
            const deviceIcon = getDeviceIcon(catalogo);
            const [color1, color2] = getPointBackgroundColor(item.valores);
            let temperatura;
            let voltage;
            if (catalogo === "mk_117") {
              voltage = item.valores == false
                ? "No ha reportado"
                : (item.valores.voltage !== undefined ? item.valores.voltage : null) + "V";
            } else {
              temperatura = item.valores == false
                ? "No ha reportado"
                : (item.valores.temperature !== undefined ? item.valores.temperature : item.valores.Tambiente) + "°C";
            }

            const tooltipTitle = catalogo === "mk_117" ? `${nombre} ${voltage}` : `${nombre} ${temperatura}`;

            return (
              <Tooltip
                key={index}
                title={tooltipTitle}
                enterDelay={500}
                enterNextDelay={500}
                leaveDelay={200}
                onClose={handlePointMouseLeave}
              >
                <div
                  className="point"
                  style={{
                    left: `${x}%`,
                    top: `${y}%`,
                    background: catalogo === "mk_117" ? "black" : `linear-gradient(to right, ${color1}, ${color2})`,
                    color: logoColor,
                  }}
                >
                  <div
                    className="icon-container"
                    onMouseLeave={handlePointMouseLeave}
                    onClick={() =>
                      NextPage(deviceID + "-" + catalogo + "-" + nombre)
                    }
                  >
                    {deviceIcon}
                  </div>
                </div>
              </Tooltip>
            );
          })}
        </>
      )}
    </div>
  );
};

export default ImageWithPoints;
