import React, { useState, useEffect, useRef } from "react";
import { ItemTypes } from "./ItemTypes";
import { useDrop } from "react-dnd";
import LineChart from "../Charts/LineChart";
import BarChart from "../Charts/BarChart";
import PieChart from "../Charts/PieChart";
import Humedad from "../Charts/humedad"
import Temperatura from "../Charts/TemperaturaM"
import Bateria from "../Charts/Bateria"
import Texto from "../Charts/Texto"
import Display from "../Charts/Display/Display"
import DisplayTest from "../Charts/Display/DisplayTest"
import RGL, { WidthProvider } from "react-grid-layout";
//  import css -- IMP!!!
// import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import "./Content.css";
import "./testing.css";
import Testing from "../Charts/LineChat2";
import TankLevel from "../Charts/TankLevel";
import ModalR from "../../Modal/Modal right-left/index";
import Edision from "../../../views/Dashboard/Edision";
import Gauge from "../Charts/GaugeChart";
const ReactGridLayout = WidthProvider(RGL);

const Content = (props) => {
  const [activeDrag, setActiveDrag] = useState(null);
  const [Tamanio, setTamanio] = useState(null);
  let datas = [10, 20, 30, 0, 40, 55, 80];
  const [showModall, setShowModall] = useState(false);
  const [edicion, setEdicion] = useState(null);
  const [actualizar, setActualizar] = useState(false);
  const [widgetCreated, setWidgetCreated] = useState(false); // Estado para rastrear si se creó un widget

  const childRef = useRef(null);

  useEffect(() => {
    setActualizar(!actualizar)
  }, [props.newData, props.dataD]);

  const handleWidgetCreation = () => {
    setWidgetCreated(true); // Cuando se crea un widget, establecer widgetCreated en true
  };

  const ProbandoFu = () => {
  };

  const handleDragStop = (layout, oldItem, newItem, placeholder, e, element) => {
    const activeWidget = props.newData.find((w) => w.identificador === oldItem.i);
    setActiveDrag(activeWidget);
  };


  // function handleDrag(event) {
  //   const parentWidth = event.currentTarget.offsetWidth;
  //   const parentHeight = event.currentTarget.offsetHeight;
  //   const child = childRef.current;

  //   const newFontSize = Math.min(parentWidth, parentHeight) / 10;
  //   child.style.fontSize = newFontSize + "px";
  //   setTamanio(child.style.fontSize)
  // }


  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop: (item, monitor) => {
      props.setNewData((old) => {
        props.change([...old, { name: item.name, id: item.id }]);
        return [...old, { name: item.name, id: item.id }];
      });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  const handleShowModall = () => {
    props.ModalEdit(true)
  };

  const handleCloseModall = () => {
    props.ModalEdit(false)
  };

  const EliminarW = (data) => {
    setEdicion(data)
    handleShowModall()
  }

  const Fecha = (fecha) => {
    const date = new Date(fecha);
    date.setHours(date.getHours() + 5);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}`;

    return { año: formattedDate, hora: formattedTime };
  }
  return (
    <div>

      <ModalR isOpen={props.estadoM} onClose={handleCloseModall}>
        <Edision datas={edicion} closeM={handleCloseModall} idDashboard={props.idDashboard} funcionEliminar={props.funcionEliminar} funcionActualizar={props.consumir} />
      </ModalR>


      <div ref={drop} style={{ maxWidth: "100%", height: "100%" }}>


        <ReactGridLayout
          cols={24}
          onResize={props.onResize}
          width={100}
          layout={props.dataD}
          rowHeight={100}
          onLayoutChange={props.onLayoutChange}
          isBounded={true}
          draggableCancel=".no-drag"
          autoSize={true}
        >
          {props.newData.length !== 0 ? (
            props.newData.map((ele, index) => {
              return (
                <div key={index + 1}>
                  <div className="drag-handle"></div>
                  <div className="no-drag" style={{ width: '100%', height: '100%' }}>
                    {ele.hasOwnProperty('tipo') ? (
                      ele.name === "LineChart" && ele.tipo === "test" ? (
                        <DisplayTest
                          info={ele}
                          nombre={ele.nombre}
                          index={index}
                          funcionE={EliminarW}
                          funcionF={Fecha}
                          actualizar={actualizar}
                          ref={childRef}
                        />
                      ) : null

                    ) : (
                      ele.name === "LineChart" ? (
                        <LineChart
                          info={ele}
                          nombre={ele.nombre}
                          index={index}
                          funcionE={EliminarW}
                          funcionF={Fecha}
                          actualizar={actualizar}
                          ref={childRef}
                        />
                      ) : ele.name === "Metrica" ? (
                        <BarChart
                          info={ele}
                          data={ele.data}
                          nombre={ele.nombreD}
                          index={index}
                          funcionE={EliminarW}
                          funcionF={Fecha}
                          ref={childRef}
                        />
                      ) : ele.name === "Humedad" ? (
                        <Humedad
                          seriesData={ele.data}
                          info={ele}
                          funcionE={EliminarW}
                          funcionF={Fecha}
                          index={index}
                          actualizar={actualizar}
                          ref={childRef}
                        />
                      ) : ele.name === "Temperatura" ? (
                        <Temperatura
                          info={ele}
                          funcionE={EliminarW}
                          funcionF={Fecha}
                          index={index}
                          actualizar={actualizar}
                          margin={[10, 10]}
                        />
                      ) : ele.name === "Batería" ? (
                        <Bateria
                          data={ele.data}
                          info={ele}
                          nombre={ele.nombre}
                          funcionE={EliminarW}
                          funcionF={Fecha}
                          index={index}
                          actualizar={actualizar}
                          ref={childRef}
                        />
                      ) : ele.name === "Texto" ? (
                        <Texto
                          data={ele.data}
                          info={ele}
                          index={index}
                          funcionE={EliminarW}
                          backgroundColor={ele.backgroundColor}
                          ref={childRef}
                        />
                      ) : ele.name === "Entrada" ? (
                        <Display
                          data={ele.data}
                          info={ele}
                          index={index}
                          funcionE={EliminarW}
                          funcionF={Fecha}
                          backgroundColor={ele.backgroundColor}
                          ref={childRef}
                        />
                      ) : ele.name === "Gauge" ? (
                        <Gauge
                          data={ele.data}
                          info={ele}
                          index={index}
                          funcionE={EliminarW}
                          funcionF={Fecha}
                          backgroundColor={ele.backgroundColor}
                          actualizar={actualizar}
                          ref={childRef}
                        />
                      ) : (
                        <TankLevel ref={childRef} />
                      )
                    )}
                  </div>
                </div>
              );
            })
          ) : (
            <div style={{ height: 200 }}></div>
          )}
        </ReactGridLayout>

      </div>
      {activeDrag && props.onDragStop && props.onDragStop(activeDrag.identificador)}
    </div>
  );
};

export default Content;