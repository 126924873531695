import styled from "styled-components";

export const Titulo = styled.div`
  margin: 10px 0 0 0;
  font-size: 25px;
  text-align: center;
  border-right: 4px dotted #0d6bf7;
border-top: 4px dotted #0d6bf7;
border-left: 4px dotted #0d6bf7;
  ${'' /* background: red;  */}
`;