import { useState } from "react";
import AutomaticReport from "./AutomaticReport";
import GenerateReport from "./GenerateReport";
import ShowAutomaticReport from "./ShowAutomaticReport";
import Accordion from "@mui/material/Accordion";
import { Button } from "react-bootstrap";
import {
  Main,
  ContenedorFechas,
  ContenedorTitulo,
} from "../Historico/ElementosStyles";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useHistory } from "react-router-dom";

const Reports = () => {
  const history = useHistory();
  const [changeTable, setChangeTable] = useState(false);

  const atras = () => {
    history.goBack();
  };

  return (
    <>
      <Main>
        <ContenedorFechas>
          <Button onClick={atras}>Atras</Button>
        </ContenedorFechas>
        <ContenedorTitulo>Reportes</ContenedorTitulo>
          <Accordion slotProps={{ heading: { component: "h4" } }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: "#f0f0f0" }}
            >
              <b>Generar Reporte</b>
            </AccordionSummary>
            <AccordionDetails>
              <GenerateReport />
            </AccordionDetails>
          </Accordion>
          <Accordion slotProps={{ heading: { component: "h4" } }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: "#f0f0f0" }}
            >
              <b>Programar Reporte</b>
            </AccordionSummary>
            <AccordionDetails>
              <AutomaticReport
                setChangeTable={setChangeTable}
                changeTable={changeTable}
              />
            </AccordionDetails>
          </Accordion>
          <Accordion slotProps={{ heading: { component: "h4" } }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ backgroundColor: "#f0f0f0" }}
            >
              <b>Ver Reportes Programados</b>
            </AccordionSummary>
            <AccordionDetails>
              <ShowAutomaticReport changeTable={changeTable} />
            </AccordionDetails>
          </Accordion>
      </Main>
    </>
  );
};

export default Reports;
