import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import "./Content.css"
import {
    CtituloWi,
    CtituloLe,
    IconoDes,
} from "../../../views/Dashboard/DashboardElements";

const BarrasWid = ({ Titulo, children }) => {

    const [mostrarTexto, setMostrarTexto] = useState(true);

    function girarIcono(event) {
        const icono = event.currentTarget.querySelector(".icono-des");
        icono.classList.toggle("rotar-icono");
        setMostrarTexto(!mostrarTexto);
    }

    return (
        <>
            <CtituloWi onClick={girarIcono}>
                <CtituloLe>{Titulo}</CtituloLe>
                <IconoDes><ExpandMoreIcon className="icono-des" /></IconoDes>
            </CtituloWi>
            {mostrarTexto && (
                <div>
                    {children}
                </div>
            )}
        </>
    )
}

export default BarrasWid;