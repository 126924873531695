import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TextField from "@mui/material/TextField";
import API from "../../services/api-service";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { Button, Form } from "react-bootstrap";
import toast, { Toaster } from 'react-hot-toast';
import Tabla from "./Tabla/index";
import {
    Main,
    ContenedorTitulo,
    ContenedorFechas,
} from "./ElementosStyles";

const Index = () => {
    const itemsPerPageOptions = [50, 100, 200];
    const [controladorC, setControladorC] = useState(false);
    const query = new URLSearchParams(useLocation().search);
    const organizacionn = query.get("organizacion");
    const localizacionn = query.get("localizacion");

    // Fechas por localización
    const [fechaInicialLoca1, setFechaInicialLoca1] = useState(
        new Date().getTime() - 1000 * 60 * 60 * 24
    );
    const [fechaFinalLoca1, setFechaFinalLoca1] = useState(new Date().getTime());
    const [fechaInicialLoca2, setFechaInicialLoca2] = useState(
        new Date().getTime() - 1000 * 60 * 60 * 24
    );
    const [fechaFinalLoca2, setFechaFinalLoca2] = useState(new Date().getTime());

    const [dispositivoLoca1, setDispositivoLoca1] = useState("Todos");
    const [dispositivoLoca2, setDispositivoLoca2] = useState("Todos");
    const [dispositivosLoca1, setDispositivosLoca1] = useState([]);
    const [dispositivosLoca2, setDispositivosLoca2] = useState([]);
    const [columnValues, setColumValues] = useState([]);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchDevices = async () => {
            try {
                if (organizacionn === "gpf" && localizacionn === "gpf_loca_1") {
                    const respuesta = await API.obtenerDispositivosHumitemp({
                        organizacion: organizacionn,
                        estado: "all",
                    });
                    setDispositivosLoca1(respuesta);
                    setColumValues([
                        { nColumna: 'Dispositivo', nValor: 'nombre' },
                        { nColumna: 'T Ambiente', nValor: 'Tambiente' },
                        { nColumna: 'H Ambiente', nValor: 'Hambiente' },
                        { nColumna: 'V bat (%)', nValor: 'Vbat' },
                        { nColumna: 'Fecha', nValor: 'fechaCompleta' }
                    ]);
                } else if (organizacionn === "gpf" && localizacionn === "gpf_loca_2") {
                    setDispositivosLoca1([]);
                    const respuesta = await API.obtenerDispositivosS1({
                        organizacion: organizacionn,
                        tipo: "ble_1",
                    });
                    setDispositivosLoca2(respuesta);
                    setColumValues([
                        { nColumna: 'Dispositivo', nValor: 'mac' },
                        { nColumna: 'T Ambiente', nValor: 'temperature' },
                        { nColumna: 'H Ambiente', nValor: 'humidity' },
                        { nColumna: 'V bat (%)', nValor: 'battery' },
                        { nColumna: 'Fecha', nValor: 'fecha' }
                    ]);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchDevices();
    }, [organizacionn, localizacionn]);

    useEffect(() => {
        const fetchData = async () => {
            const toastId = toast.loading("Cargando datos...");
            setLoading(true);
            try {
                setData([]);
                let dispositivo = "Todos";
                let fechaInicial, fechaFinal;

                // Determina las fechas según la localización
                if (localizacionn === "gpf_loca_1") {
                    dispositivo = dispositivoLoca1;
                    fechaInicial = fechaInicialLoca1;
                    fechaFinal = fechaFinalLoca1;
                } else if (localizacionn === "gpf_loca_2") {
                    dispositivo = dispositivoLoca2;
                    fechaInicial = fechaInicialLoca2;
                    fechaFinal = fechaFinalLoca2;
                }

                const respuesta = await API.historicosTempGPF(dispositivo, localizacionn, fechaInicial, fechaFinal);
                if (respuesta.mensaje === "No existen documentos") {
                    toast.dismiss(toastId);
                    toast.error("No existen datos");
                    setData([]);
                } else {
                    setData(respuesta);
                    toast.dismiss(toastId);
                }
            } catch (error) {
                console.log(error);
                toast.dismiss(toastId);
                toast.error("Error al cargar los datos históricos");
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [controladorC, localizacionn, dispositivoLoca1, dispositivoLoca2]);

    const cambiarFechaI = (fecha) => {
        if (localizacionn === "gpf_loca_1") {
            setFechaInicialLoca1(new Date(fecha).getTime());
        } else if (localizacionn === "gpf_loca_2") {
            setFechaInicialLoca2(new Date(fecha).getTime());
        }
    };

    const cambiarFechaF = (fecha) => {
        if (localizacionn === "gpf_loca_1") {
            setFechaFinalLoca1(new Date(fecha).getTime());
        } else if (localizacionn === "gpf_loca_2") {
            setFechaFinalLoca2(new Date(fecha).getTime());
        }
    };

    const FechaUltima = () => {
        setControladorC(!controladorC);
    };

    const handleDeviceChange = (event) => {
        const selectedDevice = event.target.value;
        if (localizacionn === "gpf_loca_1") {
            setDispositivoLoca1(selectedDevice);
        } else if (localizacionn === "gpf_loca_2") {
            setDispositivoLoca2(selectedDevice);
        }
    };

    return (
        <Main>
            <Toaster />
            <ContenedorTitulo>Tabla de temperaturas</ContenedorTitulo>
            <ContenedorFechas>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Fecha Inicial"
                        value={localizacionn === "gpf_loca_1" ? new Date(fechaInicialLoca1) : new Date(fechaInicialLoca2)}
                        onChange={cambiarFechaI}
                    />
                </LocalizationProvider>
            </ContenedorFechas>
            <ContenedorFechas>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        renderInput={(props) => <TextField {...props} />}
                        label="Fecha Final"
                        value={localizacionn === "gpf_loca_1" ? new Date(fechaFinalLoca1) : new Date(fechaFinalLoca2)}
                        onChange={cambiarFechaF}
                    />
                </LocalizationProvider>
            </ContenedorFechas>
            <ContenedorFechas>
                <Button
                    className="buttonGeneral"
                    onClick={FechaUltima}
                    style={{
                        margin: "0px 15px",
                    }}
                >
                    Buscar en Rango
                </Button>
            </ContenedorFechas>
            <ContenedorFechas>
                <Form.Group controlId="selectDevice">
                    <Form.Label>Dispositivos:</Form.Label>
                    <Form.Select value={localizacionn === "gpf_loca_1" ? dispositivoLoca1 : dispositivoLoca2} onChange={handleDeviceChange}>
                        <option value="Todos">Todos</option>
                        {(localizacionn === "gpf_loca_1" ? dispositivosLoca1 : dispositivosLoca2).map((device, index) => (
                            <option key={index} value={device.deviceID}>
                                {device.nombre}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </ContenedorFechas>
            {loading ? (
                <div>Cargando...</div>
            ) : data.length > 0 ? (
                <Tabla data={data} itemsPerPageOptions={itemsPerPageOptions} columnValues={columnValues} />
            ) : (
                <div>No hay datos disponibles.</div>
            )}
        </Main>
    );
};

export default Index;
