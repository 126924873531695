import React, { useState, useMemo } from 'react'
import TextField from "@mui/material/TextField";
import { SketchPicker as Picker } from 'react-color';
import { ContenedorTitulo, Main, Opciones, BotonGuardar } from '../Elements'

const decimalToHex = (alpha) =>
    alpha === 0 ? '00' : Math.round(255 * alpha).toString(16);

const Texto = ({ data, saveData }) => {

    const [tituloWidget, setTituloWidget] = useState("")
    const [color, setColor] = useState("#fafafe");
    const hexColor = useMemo(() => {
        if (typeof color === 'string') {
            return color;
        }
        return `${color?.hex}${decimalToHex(color?.rgb?.a)}`;
    }, [color]);


    const GuardarData = () => {
        let maxY
        let cantidad
        let maxX
        if (Object.keys(data).length == 4) {
            maxY = 0
            cantidad = 1
            data.posiciones = []
            data.identificadores = []
        } else {
            maxY = data.posiciones[0].y;
            maxX = data.posiciones[0].x;
            cantidad = data.posiciones.length + 1;
            cantidad = cantidad.toString();

            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y > maxY) {
                    maxY = data.posiciones[i].y;
                }
            }
            for (let i = 1; i < data.posiciones.length; i++) {
                if (data.posiciones[i].y == maxY) {
                    if (data.posiciones[i].x > maxX) {
                        maxX = data.posiciones[i].x;
                    }
                }
            }
        }

        if (maxX > 4) {
            maxX = 0
            maxY = maxY + 1
        } else {
            maxX = maxX + 1
        }

        let nombres = {
            name: "Texto",
            backgroundColor: hexColor,
            data: tituloWidget
        };

        let newWidget = {
            w: 9,
            h: 1,
            x: maxX,
            y: maxY,
            i: cantidad,
            moved: false,
            static: false,
        };
        data.posiciones.push(newWidget);
        data.identificadores.push(nombres);
        saveData(data)
    };

    const VerificarTituloWidget = (e) => {
        const inputValue = e.target.value;
        setTituloWidget(inputValue)
    }
    return (
        <>
            <Main>
                <ContenedorTitulo>
                    Parámetros
                </ContenedorTitulo>
                <TextField
                    label="Titulo Widget"
                    value={tituloWidget}
                    onChange={VerificarTituloWidget}
                />
                <Opciones>
                    <Picker color={hexColor} onChange={setColor} />
                </Opciones>
                <BotonGuardar onClick={GuardarData}>Guardar</BotonGuardar>
            </Main>
            {/* <button onClick={GuardarData}>Aplasta aqui</button> */}
        </>
    )
}

export default Texto