import React, { useState, useEffect, useRef } from "react";
import EditIcon from '@material-ui/icons/Edit';
import BatteryGauge from 'react-battery-gauge';
import "./humedad.css";
import { RiErrorWarningLine } from "react-icons/ri";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {
    Actalizacion,
    Contenedor,
    ContenedorWa,
    IconoEdit,
    ImagenWid,
    IndicadorT,
    Main,
    NombreD,
    StyledReactGridItem,
    Testing,
    Titulo
} from './ElementsDisplay';

const centerTextStyles = {
    textAlign: 'center',
};

const centerVerticallyStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40%',
    paddingBot: '10px',
};

const App = (props) => {

    const controladorDevice = props.info.viwes.Mdevice || false
    const controladorNombre = props.info.viwes.Mnombre || false
    const nombre = props.info.nombre || false
    const idDispositivo = props.info.device || false
    const nombreWidget = props.info.tituloWidget || false
    const background = props.info.background || "#ffffff"
    const {año, hora} = props?.funcionF(props.info.ultimoDato)
    const containerRef = useRef(null);


    const index = props.index
    const info = props.info

    const handleMouseOver = (event) => {
        event.target.style.cursor = 'pointer';
    }

    const propsBattery = {
        batteryBody: {
            strokeWidth: 2,
            cornerRadius: 4,
            fill: 'none',
            strokeColor: '#111'
        },
        batteryCap: {
            fill: 'none',
            strokeWidth: 4,
            strokeColor: '#111',
            cornerRadius: 2,
            capToBodyRatio: 0.4
        },
        batteryMeter: {
            fill: 'green',
            lowBatteryValue: 15,
            lowBatteryFill: 'red',
            outerGap: 1,
            noOfCells: 1, // more than 1, will create cell battery
            interCellsGap: 1
        },
        readingText: {
            lightContrastColor: '#111',
            darkContrastColor: '#fff',
            lowBatteryColor: 'red',
            fontFamily: 'Poppins',
            fontSize: 14,
            showPercentage: true
        },
        chargingFlash: {
            scale: undefined,
            fill: 'orange',
            animated: true,
            animationDuration: 1000
        },
    }

    return (
        <Main background={background} ref={containerRef} className="widgets-font">


            <IconoEdit>
                <EditIcon onClick={() => props.funcionE({ index: index, widget: info })} onMouseOver={handleMouseOver} />
            </IconoEdit>
            <Contenedor>
                {nombreWidget ? <p style={centerTextStyles} size="100%"><strong>{nombreWidget}</strong></p> : null}
                {controladorNombre != false ? <p style={centerTextStyles}>{nombre}</p> : null}
                {controladorDevice != false ? <p style={centerTextStyles}>{idDispositivo}</p> : null}
                {props.data ? <div style={centerVerticallyStyles}><BatteryGauge value={props.data} size="100%" animated={true} aspectRatio={0.28} customization={propsBattery}// Cambiar el tamaño al 100%
                    style={{

                        width: "50%", // Ajustar el ancho al 100%
                        height: "50%" // Ajustar la altura al 100%
                        // }} /></div> : <p style={centerTextStyles}>No han reportado</p>}
                    }} /></div> :

                    <div  style = {{
                        textAlign:"center"
                    }}>
                        <p>No ha reportado <ErrorOutlineIcon/></p>
                        {/* <RiErrorWarningLine value={{color:"blue"}}></RiErrorWarningLine> */}
                        {/* <RiErrorWarningLine /> */}
                    </div>

                }
            </Contenedor>
            
        </Main>
    );
}

export default App;
