import React from 'react';
import ReactApexChart from "react-apexcharts";
import EditIcon from '@material-ui/icons/Edit';
import { Resizable } from 'react-resizable';
import { Actalizacion, Contenedor, ContenedorWa, IconoEdit, ImagenWid, IndicadorT, Main, NombreD, StyledReactGridItem, Testing, Titulo } from './ElementsDisplay';
import ZingChart from 'zingchart-react';

class StreamingDemo extends React.Component {
  constructor(props) {
    super(props);
    let lengths = props.info.data.map(item => item.x.length);
    let largestLength = Math.max(...lengths);
    let index = lengths.indexOf(largestLength);
   
    const series = props.info.device.map((_, i) => {
      return {
        'values': props.info.data[i].y,
        'data-days': props.info.data[i].x,
        'aspect': 'spline',
        'alpha-area': '.5',
        'font-family': 'Roboto',
        'font-size': '14px',
        "tooltip": {
          "font-family": "Roboto",
          "font-size": "15px",
          "text": props.info.variable[i] + " %v en %data-days",
          "text-align": "left",
          "border-radius": 5,
          "padding": 10
        },
      }
    });

    const labs = [];
    for (let i = 0; i <= props.info.variable.length; i++) {
      labs.push({
        "text": props.info.variable[i] + ": %plot-" + i + "-value" + " ",
        "default-value": "",
        "x": `${i * 100 / props.info.variable.length}%`,
        "y": 22,
        "width": 120,
        "text-align": "center",
        "bold": 0,
        "font-size": "16px",
        "font-weight": "bold"
      });
      if (i == props.info.device.length) {
        for (let j = 0; j < props.info.device.length; j++) {
          labs.push({
            "text": props.info.nombre[j] === "Sin nombre" || props.info.nombre[j] === null ? props.info.device[j] : props.info.nombre[j],
            "default-value": "",
            "x": `${j * 100 / props.info.variable.length}%`,
            "y": 0,
            "width": 120,
            "text-align": "center",
            "bold": 0,
            "font-size": "14px",
            "font-weight": "bold"
          });
        }
      }
    }

    this.state = {
      nombreWidget: this.props.info.tituloWidget || false,
      nombeDiposositivo: this.props.info.nombre || false,
      idDispositivo: this.props.info.device || false,
      info: this.props.info,
      config: {
        "globals": {
          "font-family": "Roboto"
        },
        "graphset": [
          {
            "type": "area",
            "background-color": "#fff",
            "utc": true,
            "height": "90%",
            "preview": {
              "x": "5%",
              "y": "100%",
              "width": "100%",
              "height": "10%"
            },
            "title": {
              "y": "15px",
              "background-color": "none",
              "font-color": "#05636c",
              "font-size": "24px",
              "height": "25px",
              "adjust-layout": true
            },
            "plotarea": {
              "margin-top": "10%",
              "margin-right": "dynamic",
              "margin-bottom": "dynamic",
              "margin-left": "dynamic",
              "adjust-layout": true
            },
            "labels": labs,
            "scale-x": {
              "zooming": true,
              "label": {
                "text": "",
                "font-size": "14px",
                "font-weight": "normal",
                "offset-x": "10%",
                "font-angle": 360
              },
              "item": {
                "text-align": "center",
                "font-color": "#05636c"
              },
              "max-labels": 6,
              "max-items": 6,
              "labels": this.props.info.data[index].x,
              "items-overlap": true,
              "guide": {
                "line-width": "0px"
              },
              "tick": {
                "line-width": "2px"
              },
            },
            "scale-y": {
              "zooming": true,

              "item": {
                "font-color": "#05636c",
                "font-weight": "normal"
              },
              "label": {
                "font-size": "14px"
              },
              "guide": {
                "line-width": "0px",
                "alpha": 0.2,
                "line-style": "dashed"
              }
            },
            "plot": {
              "line-width": 2,
              "marker": {
                "size": 1,
                "visible": false
              }
            },
            "crosshair-x": {
              "line-color": "#fff",
              "line-width": 1,
              "plot-label": {
                "visible": false
              },
              "scale-label": {
                "visible": false
              }
            },
            "crosshair-y": {
              "type": "multiple",
              "scale-label": {
                "visible": false
              }
            },
            "series": series,

          }
        ]
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.actualizar !== this.props.actualizar) {
      const series = prevProps.info.device.map((_, i) => {
        return {
          'values': prevProps.info.data[i].y,
          'data-days': prevProps.info.data[i].x,
          'aspect': 'spline',
          'alpha-area': '.5',
          'font-family': 'Roboto',
          'font-size': '14px',
          "tooltip": {
            "font-family": "Roboto",
            "font-size": "15px",
            "text": prevProps.info.variable[i] + " %v en %data-days",
            "text-align": "left",
            "border-radius": 5,
            "padding": 10
          },
        }
      });
      const labs = [];
      for (let i = 0; i <= prevProps.info.variable.length; i++) {
        labs.push({
          "text": prevProps.info.variable[i] + ": %plot-" + i + "-value" + " ",
          "default-value": "",
          "x": `${i * 100 / prevProps.info.variable.length}%`,
          "y": 22,
          "width": 120,
          "text-align": "center",
          "bold": 0,
          "font-size": "16px",
          "font-weight": "bold"
        });
        if (i == prevProps.info.device.length) {
          for (let j = 0; j < prevProps.info.device.length; j++) {
            labs.push({
              "text": prevProps.info.nombre[j] === "Sin nombre" || prevProps.info.nombre[j] === null ? prevProps.info.device[j] : prevProps.info.nombre[j],
              "default-value": "",
              "x": `${j * 100 / prevProps.info.variable.length}%`,
              "y": 0,
              "width": 120,
              "text-align": "center",
              "bold": 0,
              "font-size": "14px",
              "font-weight": "bold"
            });
          }
        }
      }

      let lengths = prevProps.info.data.map(item => item.x.length);
      let largestLength = Math.max(...lengths);
      let index = lengths.indexOf(largestLength);
     

      this.eliminarWidget = this.props.funcionE.bind(this);

      this.setState({
        nombreWidget: this.props.info.tituloWidget || false,
        nombeDiposositivo: this.props.info.nombre || false,
        idDispositivo: this.props.info.device || false,
        info: this.props.info,
        config: {
          "globals": {
            "font-family": "Roboto"
          },
          "graphset": [
            {
              "type": "area",
              "background-color": "#fff",
              "utc": true,
              "height": "90%",
              "preview": {
                "x": "5%",
                "y": "100%",
                "width": "100%",
                "height": "10%"
              },
              "title": {
                "y": "15px",
                "background-color": "none",
                "font-color": "#05636c",
                "font-size": "24px",
                "height": "25px",
                "adjust-layout": true
              },
              "plotarea": {
                "margin-top": "10%",
                "margin-right": "dynamic",
                "margin-bottom": "dynamic",
                "margin-left": "dynamic",
                "adjust-layout": true
              },
              "labels": labs,
              "scale-x": {
                "zooming": true,
                "label": {
                  "text": "",
                  "font-size": "14px",
                  "font-weight": "normal",
                  "offset-x": "10%",
                  "font-angle": 360
                },
                "item": {
                  "text-align": "center",
                  "font-color": "#05636c"
                },
                "max-labels": 6,
                "max-items": 6,
                "labels": this.props.info.data[index].x,
                "items-overlap": true,
                "guide": {
                  "line-width": "0px"
                },
                "tick": {
                  "line-width": "2px"
                },
              },
              "scale-y": {
                "zooming": true,

                "item": {
                  "font-color": "#05636c",
                  "font-weight": "normal"
                },
                "label": {
                  "font-size": "14px"
                },
                "guide": {
                  "line-width": "0px",
                  "alpha": 0.2,
                  "line-style": "dashed"
                }
              },
              "plot": {
                "line-width": 2,
                "marker": {
                  "size": 1,
                  "visible": false
                }
              },
              "crosshair-x": {
                "line-color": "#fff",
                "line-width": 1,
                "plot-label": {
                  "visible": false
                },
                "scale-label": {
                  "visible": false
                }
              },
              "crosshair-y": {
                "type": "multiple",
                "scale-label": {
                  "visible": false
                }
              },
              "series": series,

            }
          ]
        }
      }
      )
    }
  }

  render() {
    return (
      <Main>
        {this.props.info.tituloWidget != false ? <h4>{this.props.info.tituloWidget}</h4> : null}
        <>
          <Contenedor>
            <div style={{ width: '100%', height: '70%' }}>

              <div style={{ width: '100%', height: '100%' }}>
                <ZingChart data={this.state.config} height="300px" width="900px" />
              </div>

            </div>
          </Contenedor>
        </>
      </Main>


    );
  }
}


export default StreamingDemo;